import {useEffect, useState} from "react";

const Image = (props) => {
    const [image, setImage] = useState();
    const [title, setTitle] = useState('');
    const handleUpload = (e) => {
        setImage(e.target.files[0]);
    }

    useEffect(() => {
        setTitle(props.title);
    }, []);

    const handleTitleChange = (e) => {
        setTitle(e.target.value);
    }

    const saveImage = () => {
        props.handleUpload(image, title);
    }
    return (
        <>
            <input type="file" disabled={!props.editable} onChange={handleUpload}/>
            <input type="text" value={props.title} disabled={!props.editable} onChange={handleTitleChange} />
            {props.editable ? <span onClick={saveImage}>save</span> : ''}

        </>
    )
}

export default Image;