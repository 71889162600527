import styles from './PredlagajPage.module.css';
import PreviousProjects from "../Parts/PreviousProjects/PreviousProjects";
import image1 from './assets/image1.svg';
import image2 from './assets/image2.svg';

const PredlagajPage = () => {
    return (
        <>
            <div className={`${styles.predlagajTitleWrapper}`}>
                <div className={`container`}>
                    <div className="row">
                        <div className="col-12 col-lg-6">
                            <div className={`${styles.predlagajtextWrapper} d-flex flex-column justify-content-center`}>
                                <h1 className={`${styles.predlagajTitle}`}>Predlagaj</h1>
                                <p className={`${styles.predlagajDescription}`}>
                                    Svoj predlog o porabi participativnega dela proračuna si lahko oddal-/a do 11. 7.
                                    2022
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className={`${styles.stepsWrapper}`}>
                            <div className={`${styles.step} d-flex flex-column flex-lg-row`}>
                                <div className={`${styles.stepTextWrapperLeft} d-flex flex-column justify-content-center`}>
                                    <h2 className={`${styles.stepTitle}`}>
                                        Kakšen je proračun?
                                    </h2>
                                    <p className={`${styles.stepContent}`}>
                                        Participativnemu proračunu je namenjenih 960.000 € proračunskih sredstev občine
                                        za
                                        leti 2023 in 2024. Vrednost posameznega predloga je omejena na od 10.000 do
                                        40.000
                                        €.
                                    </p>
                                </div>
                                <img src={image1} className={`flex-2`} alt=""/>
                            </div>
                            <div className={`${styles.step} d-flex flex-column flex-lg-row`}>

                                <img src={image2} alt=""/>
                                <div className={`${styles.stepTextWrapperRight} d-flex flex-column justify-content-center`}>
                                    <h2 className={`${styles.stepTitle}`}>
                                        Kakšen je proračun?
                                    </h2>
                                    <p className={`${styles.stepContent}`}>
                                        Participativnemu proračunu je namenjenih 960.000 € proračunskih sredstev občine
                                        za
                                        leti 2023 in 2024. Vrednost posameznega predloga je omejena na od 10.000 do
                                        40.000
                                        €.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <PreviousProjects style={{marginTop: '100px'}}/>
        </>
    )
}

export default PredlagajPage;