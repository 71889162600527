import {useState} from "react";
import Faq from "./Faq/Faq";
import styles from './FaqPage.module.css';
import faqHeader from './faqheader.svg';

const FaqPage = () => {
    const [faqs, setFaqs] = useState([
        {
            question: 'Potrebujem tel. stevilko in dostop do interneta da lahko sodelujem?',
            answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus ut enim leo. Sed in quam diam. Curabitur gravida mattis efficitur. Integer ac consectetur metus. Mauris vitae odio ac massa sagittis malesuada. Integer quis sapien sit amet est malesuada porttitor nec sit amet ligula. Curabitur porttitor dapibus justo a tempus. Pellentesque et ipsum fermentum nunc mollis porttitor ac in quam.'
        },
        {
            question: 'Question 2',
            answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus ut enim leo. Sed in quam diam. Curabitur gravida mattis efficitur. Integer ac consectetur metus. Mauris vitae odio ac massa sagittis malesuada. Integer quis sapien sit amet est malesuada porttitor nec sit amet ligula. Curabitur porttitor dapibus justo a tempus. Pellentesque et ipsum fermentum nunc mollis porttitor ac in quam.'
        },
        {
            question: 'Question 3',
            answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus ut enim leo. Sed in quam diam. Curabitur gravida mattis efficitur. Integer ac consectetur metus. Mauris vitae odio ac massa sagittis malesuada. Integer quis sapien sit amet est malesuada porttitor nec sit amet ligula. Curabitur porttitor dapibus justo a tempus. Pellentesque et ipsum fermentum nunc mollis porttitor ac in quam.'
        },
        {
            question: 'Question 4',
            answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus ut enim leo. Sed in quam diam. Curabitur gravida mattis efficitur. Integer ac consectetur metus. Mauris vitae odio ac massa sagittis malesuada. Integer quis sapien sit amet est malesuada porttitor nec sit amet ligula. Curabitur porttitor dapibus justo a tempus. Pellentesque et ipsum fermentum nunc mollis porttitor ac in quam.'
        },
        {
            question: 'Question 5',
            answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus ut enim leo. Sed in quam diam. Curabitur gravida mattis efficitur. Integer ac consectetur metus. Mauris vitae odio ac massa sagittis malesuada. Integer quis sapien sit amet est malesuada porttitor nec sit amet ligula. Curabitur porttitor dapibus justo a tempus. Pellentesque et ipsum fermentum nunc mollis porttitor ac in quam.'
        },
        {
            question: 'Question 6',
            answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus ut enim leo. Sed in quam diam. Curabitur gravida mattis efficitur. Integer ac consectetur metus. Mauris vitae odio ac massa sagittis malesuada. Integer quis sapien sit amet est malesuada porttitor nec sit amet ligula. Curabitur porttitor dapibus justo a tempus. Pellentesque et ipsum fermentum nunc mollis porttitor ac in quam.'
        }
    ])
    return (
        faqs.length > 0 ?
            <>
                <div className={`${styles.faqTitleWrapper} d-flex flex-row`}>
                    <div className={`container d-flex flex-row`}>
                        <div className={`${styles.textWrapper} d-flex flex-column justify-content-center`}>
                            <h1 className={`${styles.faqTitle}`}>Podpora uporabnikom</h1>
                            <p className={`${styles.faqDescription}`}>
                                Imate kakšno vprašanje ali posebno željo? Kontaktirajte naše sodelavce, ki vam bodo z veseljem pomagali.
                            </p>
                        </div>
                        <img src={faqHeader} alt=""/>
                    </div>
                </div>
                <div className={`container`}>
                    <div className={`${styles.faqsWrapper}`}>
                        <div className={`${styles.faqsContent}`}>
                            <h2>Pogosta vprašanja</h2>
                            {faqs.map((faq) => {
                                return (<Faq item={faq}/>);
                            })}
                        </div>
                    </div>
                </div>
            </>
            : ''


    )
}

export default FaqPage;