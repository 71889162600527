import axios from 'axios';
import style from './AddNewUser.module.css';
import React, {useEffect, useState} from "react";
import Swal from "sweetalert2";
import {useNavigate} from "react-router-dom";
import {ADMIN_ROLE, SECRETARY_ROLE, USER_ROLE} from "../../utils";

const AddNewUser = () => {

    const navigate = useNavigate();
    const [userFormData, setUserFormData] = useState({
        name: '',
        email: '',
        password: '',
        password_confirm: '',
        role: '',
        local_community_id: '',
        emso: '',
        address: '',
        phone_number: ''
    });
    const [localCommunities, setLocalCommunities] = useState([]);

    useEffect(() => {
        let userData = JSON.parse(localStorage.getItem('auth'));
        axios.get(process.env.REACT_APP_BASE_URL + '/admin/localCommunities', {
            headers: {
                'Authorization': 'Bearer ' + userData.token
            }
        })
            .then(res => {
                setLocalCommunities(res.data);
            })
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        let user = JSON.parse(localStorage.getItem('auth'));
        const formSubmit = new FormData();
        /*formSubmit.append('name', userFormData.name);
        formSubmit.append('email', userFormData.email);
        formSubmit.append('password', userFormData.password);
        formSubmit.append('role', userFormData.role);
        formSubmit.append('local_community_id', userFormData.local_community_id);
        console.log(Object.keys(formSubmit).length === 0)*/
        //console.log(userFormData)

        if( userFormData['role'] === '' || userFormData['role'] === null || userFormData['name'] === '' || userFormData['email'] === '' || userFormData['password'] === '' || userFormData['password_confirm'] === ''){
            Swal.fire({
                title: "Napaka",
                text: "Izpolniti morate vsa polja",
                icon: "error"
            });
        }
        else if( userFormData['role'] == SECRETARY_ROLE && (userFormData['local_community_id'] === '' || userFormData['local_community_id'] === "")) {
            Swal.fire({
                title: "Napaka",
                text: "Izpolniti morate tudi krajevno skupnost",
                icon: "error"
            });
        }
        else if ( userFormData['role'] == USER_ROLE && (userFormData['emso'] === '' || userFormData['address'] === '' || userFormData['phone_number'] === '')) {
            Swal.fire({
                title: "Napaka",
                text: "Izpolniti morate vsa polja",
                icon: "error"
            });
        }
        else {
            axios.post(process.env.REACT_APP_BASE_URL + '/admin/users', userFormData, {
                headers: {
                    'Authorization': 'Bearer ' + user.token
                }
            }, {})
            .then(res => {
                console.log(res.status)
                if (res.status === 200) {
                    if (userFormData['role'] === ADMIN_ROLE) {
                        Swal.fire({
                            title: "Administrator ustvarjen",
                            text: "Uporabnik \'" + userFormData['name'] + "\' z administratorskimi pravicami je bil uspešno oddan.",
                            icon: "success"
                        });
                    } else if (userFormData['role'] === SECRETARY_ROLE) {
                        Swal.fire({
                            title: "Tajnik ustvarjen",
                            text: "Uporabnik \'" + userFormData['name'] + "\' z tajniškimi pravicami je bil uspešno oddan.",
                            icon: "success"
                        });
                    } else {
                        Swal.fire({
                            title: "Uporabnik ustvarjen",
                            text: "Uporabnik \'" + userFormData['name'] + "\' je bil uspešno oddan.",
                            icon: "success"
                        });
                    }
                    return navigate('/admin/users');
                } else {
                    if(res.status === 203)
                        Swal.fire({
                            title: "Napaka",
                            text: "Gesli se ne ujemata",
                            icon: "error"
                        });
                    else {
                        Swal.fire({
                            title: "Napaka",
                            text: "Prosim kontaktirajte skrbnika",
                            icon: "error"
                        });
                        return navigate('/admin/users');
                    }
                }
            })
        }
    }

    const handleChange = (e) => {
        setUserFormData((prevState) => {
            return {...prevState, [e.target.name]: e.target.value};
        })
    }

    return (
        <div className="oddajNovPredlog">
            <div className={`${style.oddajNovPredlogTitleWrapper}`}>
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-lg-6 mx-md-auto">
                            <h1 className={`${style.oddajNovPredlogTitleWrapperTitle}`}>Dodaj novega uporabnika</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-12 col-lg-6 mx-md-auto">
                        <form className={`${style.oddajNovPredlogForm}`} onSubmit={handleSubmit}>
                            <div className={`${style.formField}`}>
                                <div className={`d-block ${style.formFieldTitleWrapper}`}>
                                    <span className={`${style.formFieldTitle} d-block`}>
                                        Uporabniška vloga
                                    </span>
                                    <select name="role" className={`${style.formInput}`} onChange={handleChange}>
                                        <option value="">Izberite</option>
                                        <option value={""+ADMIN_ROLE}>Administrator</option>
                                        <option value={""+SECRETARY_ROLE}>Tajnik</option>
                                        <option value={""+USER_ROLE}>Uporabnik</option>
                                    </select>
                                </div>
                            </div>

                            <div className={`${style.formField}`}>
                                <div className={`d-block ${style.formFieldTitleWrapper}`}>
                                    <span className={`${style.formFieldTitle} d-block`}>
                                        Ime in priimek
                                    </span>
                                    <input className={`${style.formInput}`} type="text" name="name"
                                           onChange={handleChange}/>
                                </div>
                            </div>

                            <div className={`${style.formField}`}>
                                <div className={`d-block ${style.formFieldTitleWrapper}`}>
                                    <span className={`${style.formFieldTitle} d-block`}>
                                        E-naslov
                                    </span>
                                    <input className={`${style.formInput}`} type="text" name="email"
                                           onChange={handleChange}/>
                                </div>
                            </div>

                            <div className={`${style.formField}`}>
                                <div className={`d-block ${style.formFieldTitleWrapper}`}>
                                    <span className={`${style.formFieldTitle} d-block`}>
                                        Geslo
                                    </span>
                                    <input className={`${style.formInput}`} type="password" name="password"
                                           onChange={handleChange}/>
                                </div>
                            </div>

                            <div className={`${style.formField}`}>
                                <div className={`d-block ${style.formFieldTitleWrapper}`}>
                                    <span className={`${style.formFieldTitle} d-block`}>
                                        Ponovi geslo
                                    </span>
                                    <input className={`${style.formInput}`} type="password" name="password_confirm"
                                           onChange={handleChange}/>
                                </div>
                            </div>

                            { Number(userFormData.role) === SECRETARY_ROLE ?
                                <div className={`${style.formField}`}>
                                    <div className={`d-block ${style.formFieldTitleWrapper}`}>
                                    <span className={`${style.formFieldTitle} d-block`}>
                                        Krajevna skupnost
                                    </span>
                                        <select name="local_community_id" className={`${style.formInput}`} onChange={handleChange}>
                                            <option value="">Izberite</option>
                                            {localCommunities.map((lc => {
                                                return (
                                                    <option value={lc.id}>{lc.name}</option>
                                                )
                                            }))}
                                        </select>
                                    </div>
                                </div>
                                :
                                ''
                            }

                            { Number(userFormData.role) === USER_ROLE ?
                                <div className={`${style.formField}`}>
                                    <div className={`d-block ${style.formFieldTitleWrapper}`}>
                                        <div className={`${style.formField}`}>
                                            <div className={`d-block ${style.formFieldTitleWrapper}`}>
                                                <span className={`${style.formFieldTitle} d-block`}>
                                                    EMŠO
                                                </span>
                                                <input className={`${style.formInput}`} type="text" name="emso"
                                                       onChange={handleChange}/>
                                            </div>
                                        </div>

                                        <div className={`${style.formField}`}>
                                            <div className={`d-block ${style.formFieldTitleWrapper}`}>
                                                <span className={`${style.formFieldTitle} d-block`}>
                                                    Naslov stalnega prebivališča
                                                </span>
                                                <input className={`${style.formInput}`} type="text" name="address"
                                                       onChange={handleChange}/>
                                            </div>
                                        </div>
                                        <div className={`${style.formField}`}>
                                            <div className={`d-block ${style.formFieldTitleWrapper}`}>
                                                <span className={`${style.formFieldTitle} d-block`}>
                                                    Telefonska številka
                                                </span>
                                                <input className={`${style.formInput}`} type="text" name="phone_number"
                                                       onChange={handleChange}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                ''
                            }

                            <button type="submit" className={`btn btn-primary-big`}>Dodaj uporabnika</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddNewUser;