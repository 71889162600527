import styles from './Phases.module.css';
import predlagaj from './predlagaj.svg';
import glasuj from './glasuj.svg';
import spremljaj from './spremljaj.svg';
import {Link} from "react-router-dom";

const Phases = () => {
    return (
        <div className={`${styles.phasesBackground}`}>
            <div className={`container ${styles.phasesWrapper}`}>
                <span className={`${styles.titleText}`}>Participativni proračun 23/24</span>
                <div className={`${styles.phases}`}>
                    <div className={`${styles.phase} d-flex flex-row align-items-center`}>
                        <img src={predlagaj} className={`me-2`} alt=""/>
                        <div>
                            <p className={`${styles.text1}`}>Predlagaj svoj projekt</p>
                            <p className={`${styles.text2}`}>10.jun - 10.jul 2023</p>
                            <Link to="/link" className={`${styles.link}`}>
                                Predlagaj
                            </Link>

                        </div>
                    </div>
                    <div className={`${styles.phase} d-flex flex-row align-items-center`}>
                        <img src={glasuj} className={`me-2`} alt=""/>
                        <div>
                            <p className={`${styles.text1}`}>Glasuj za najboljše</p>
                            <p className={`${styles.text2}`}>10.jun - 10.jul 2023</p>
                            <Link to="/link" className={`${styles.link}`}>
                                Glasuj
                            </Link>
                        </div>
                    </div>
                    <div className={`${styles.phase} d-flex flex-row align-items-center`}>
                        <img src={spremljaj} className={`me-2`} alt=""/>
                        <div>
                            <p className={`${styles.text1}`}>Spremljaj izvedbo</p>
                            <p className={`${styles.text2}`}>10.jun - 10.jul 2023</p>
                            <Link to="/link" className={`${styles.link}`}>
                                Spremljaj
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Phases;