import axios from "axios";
import { useContext, useState } from "react";
import {Link, useNavigate} from "react-router-dom";
import UserContext from "../Contexts/user.context";
import styles from './LoginAdmin.module.css';
import pic from './form_picture.svg';

const LoginAdmin = () => {
    const navigate = useNavigate();
    const [user, setUser] = useContext(UserContext);
    const [formData, setFormData] = useState({
        email: '',
        password: ''
    })

    const changeHandler = (e) => {
        setFormData((prevState) => {
            return {...prevState, [e.target.name]: e.target.value};
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        axios.post(process.env.REACT_APP_BASE_URL + '/adminlogin', formData)
            .then(res => {
                if(res.status === 200) {
                    localStorage.setItem('auth', JSON.stringify(res.data));
                    setUser(res.data);
                    return navigate('/');
                }
            })
    }

    return (
        <div className="container">
            <div className={`${styles.centeringWrapper} col-5 mx-auto d-flex justify-content-center`}>
                <form className={`justify-content-center`} onSubmit={handleSubmit}>
                    <h1 className={`${styles.title}`}>Prijava</h1>
                    <br/>
                    <div>
                        <label className={`${styles.labela}`}>Elektronski naslov ali uporabniško ime</label>
                    </div>
                    <div>
                        <input className={`${styles.inputa}`} type="text" name="email" placeholder="npr.: janez.novak@gmail.com" onChange={changeHandler}/>
                    </div>
                    <br/>
                    <div className={`${styles.banana} row-1 align-items-center justify-content-center`}>
                        <label className={`${styles.labela}`}>Geslo</label>
                        <Link to='/projects' className={`${styles.howToParticipateReadMore}`}>Pozabljeno geslo?</Link>
                    </div>
                    <div>
                        <input className={`${styles.inputa}`} type="password" name="password" onChange={changeHandler}/>
                    </div>
                    <br/>
                    <button className={`${styles.gumb}`} type="submit">Login</button>
                    <br/>
                    <br/>
                    <Link to='/projects' className={`${styles.howToParticipateReadMore}`}>Nisi prejel navodil kako
                        aktivirati svoj račun?</Link>
                    <br/>
                    <br/>
                    <br/>
                    <br/>

                    <img src={pic} alt=""/>

                </form>
            </div>
        </div>
    )
}

export default LoginAdmin;