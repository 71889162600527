import axios from 'axios';
import style from './EditAdmin.module.css';
import React, {useEffect, useState} from "react";
import Swal from "sweetalert2";
import {useNavigate, useParams} from "react-router-dom";
import {ADMIN_ROLE, SECRETARY_ROLE, USER_ROLE} from "../../utils";

const EditAdmin = () => {

    const navigate = useNavigate();
    const params = useParams();
    const [userFormData, setUserFormData] = useState({
        name: '',
        email: '',
        password: '',
        password_confirm: '',
        role: '',
        local_community_id: ''
    });
    const [localCommunities, setLocalCommunities] = useState([]);

    useEffect(() => {
        let userData = JSON.parse(localStorage.getItem('auth'));
        axios.get(process.env.REACT_APP_BASE_URL + '/admin/users/' + params.id, {
            headers: {
                'Authorization': 'Bearer ' + userData.token
            }
        })
            .then(res => {
                setUserFormData({
                    name: res.data.name,
                    email: res.data.email,
                    password: '',
                    password_confirm: '',
                    role: res.data.role.toString(),
                    local_community_id: res.data.local_community_id
                })
            })

        axios.get(process.env.REACT_APP_BASE_URL + '/admin/localCommunities', {
            headers: {
                'Authorization': 'Bearer ' + userData.token
            }
        })
            .then(res => {
                setLocalCommunities(res.data);
            })
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        let user = JSON.parse(localStorage.getItem('auth'));
        axios.put(process.env.REACT_APP_BASE_URL + '/admin/users/' + params.id, userFormData, {
            headers: {
                'Authorization': 'Bearer ' + user.token
            }
        }, {})
            .then(res => {
                if(res.status === 200) {
                    Swal.fire({
                        title: "Uporabnik posodobljen",
                        text: "Uporabnik je bil uspešno posodobljen",
                        icon: "success"
                    });

                    return navigate('/admin/users');
                }
            })
    }

    const handleDelete = () => {
        let user = JSON.parse(localStorage.getItem('auth'));
        axios.delete(process.env.REACT_APP_BASE_URL + '/admin/users/delete/' + params.id, {
            headers: {
                'Authorization': 'Bearer ' + user.token
            }
        }, {})
            .then(res => {
                if(res.status === 200) {
                    Swal.fire({
                        title: "Uporabnik izbrisan",
                        text: "Uporabnik je bil uspešno izbrisan",
                        icon: "success"
                    });
                    return navigate('/admin/users');
                }
            })
            .catch((error) =>{
                if (error){}
                else{}
                return navigate('/admin/users');
            })
    }

    const handleChange = (e) => {
        setUserFormData((prevState) => {
            return {...prevState, [e.target.name]: e.target.value};
        })
    }

    function getTitleBasedOnUsersRole(){
        if ( Number(userFormData.role) === ADMIN_ROLE)
            return 'administratorja';
        else if ( Number(userFormData.role) === SECRETARY_ROLE)
            return 'tajnika';
        else
            return 'uporabnika';
    }

    return (
        <div className="oddajNovPredlog">
            <div className={`${style.oddajNovPredlogTitleWrapper}`}>
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-lg-6">
                            <h1 className={`${style.oddajNovPredlogTitleWrapperTitle}`}>Uredi {getTitleBasedOnUsersRole()}</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-12 col-lg-6">
                        <form className={`${style.oddajNovPredlogForm}`} onSubmit={handleSubmit}>
                            <div className="grid">
                                <div className="item">
                                    <div className={`${style.banana} row`}>
                                        <div className={`${style.formField}`}>
                                            <div className={`d-block ${style.formFieldTitleWrapper}`}>
                                                <span
                                                    className={`${style.formFieldTitle} d-block`}> Ime in priimek </span>
                                                <input className={`${style.formInput}`} type="text" name="name"
                                                       value={userFormData.name} onChange={handleChange}/>
                                            </div>
                                        </div>
                                        <div className={`${style.formField}`}>
                                            <div className={`d-block ${style.formFieldTitleWrapper}`}>
                                                <span className={`${style.formFieldTitle} d-block`}> E-naslov </span>
                                                <input className={`${style.formInput}`} type="text" disabled
                                                       name="email" value={userFormData.email} onChange={handleChange}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className={`${style.banana} row`}>
                                        <div className={`${style.formField}`}>
                                            <div className={`d-block ${style.formFieldTitleWrapper}`}>
                                            <span className={`${style.formFieldTitle} d-block`}>
                                                Geslo
                                            </span>
                                                <input className={`${style.formInput}`} type="password" name="password"
                                                       onChange={handleChange}/>
                                            </div>
                                        </div>
                                        <div className={`${style.formField}`}>
                                            <div className={`d-block ${style.formFieldTitleWrapper}`}>
                                            <span className={`${style.formFieldTitle} d-block`}>
                                                Ponovi geslo
                                            </span>
                                                <input className={`${style.formInput}`} type="password"
                                                       name="password_confirm" onChange={handleChange}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className={`${style.banana} row`}>
                                        <div className={`${style.formField}`}>
                                            <div className={`d-block ${style.formFieldTitleWrapper}`}>
                                            <span className={`${style.formFieldTitle} d-block`}>
                                                Uporabniška vloga
                                            </span>
                                                <select name="role" className={`${style.customSelect}`} onChange={handleChange}>
                                                    <option value="">Izberite</option>
                                                    <option selected={ Number(userFormData.role) === ADMIN_ROLE} value={""+ADMIN_ROLE}>Administrator</option>
                                                    <option selected={ Number(userFormData.role) === SECRETARY_ROLE} value={""+SECRETARY_ROLE}>Tajnik </option>
                                                    <option selected={ Number(userFormData.role) === USER_ROLE} value={""+USER_ROLE}>Uporabnik</option>
                                                </select>
                                            </div>
                                        </div>

                                        { Number(userFormData.role) === SECRETARY_ROLE ?
                                            <div className={`${style.formField}`}>
                                                <div className={`d-block ${style.formFieldTitleWrapper}`}>
                                                <span className={`${style.formFieldTitle} d-block`}>
                                                    Krajevna skupnost
                                                </span>
                                                    <select name="local_community_id" className={`${style.customSelect}`} onChange={handleChange}>
                                                        <option value="">Izberite</option>
                                                        {localCommunities.map((lc => {
                                                            return (
                                                                <option selected={userFormData.local_community_id === lc.id} value={lc.id}>{lc.name}</option>
                                                            )
                                                        }))}
                                                    </select>
                                                </div>
                                            </div>
                                        :
                                            ''
                                        }
                                    </div>
                                </div>
                                <button type="submit" className={`btn btn-primary-big`}>Posodobi administratorja</button>
                            </div>
                        </form>
                        <button className={`${style.deleteUser}`} onClick={handleDelete}>Briši uporabnika</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditAdmin;