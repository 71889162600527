import {useState} from "react";
import styles from './Faq.module.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown, faChevronUp} from "@fortawesome/free-solid-svg-icons";

const Faq = ({item}) => {
    const [open, setOpen] = useState(false);

    const faqOpen = () => {
        setOpen(!open);
    }
    return (
        <div className={`${styles.faqWrapper}`}>
            <div className={`${styles.faq}`}>
                <div className={`${styles.faqQuestion} d-flex flex-row align-items-center justify-content-center`} onClick={faqOpen} style={{backgroundColor: open ? '#00B4D9' : ''}}>
                    <span className={`d-inline-block ${styles.faqQuestionText}`} style={{color: open ? '#FFFFFF' : '#005465'}}>{item.question}</span>
                    {open ?
                        <FontAwesomeIcon className={`ms-auto`} style={{color: '#FFFFFF'}} icon={faChevronUp} />
                    :
                        <FontAwesomeIcon className={`ms-auto`} style={{color: '#005465'}} icon={faChevronDown} />
                    }

                </div>
                {open ?
                    <div className={`${styles.faqAnswer}`}>
                        <span>{item.answer}</span>
                    </div>
                    : ''}
            </div>
        </div>
    )
}

export default Faq;