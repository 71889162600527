import styles from './Profile.module.css';
import {useContext, useEffect} from "react";
import UserContext from "../../Contexts/user.context";
import {Link} from "react-router-dom";
import {integerToCurrency, MAX_ALLOWED_RESOURCES} from "../../../utils";
import profile_icon from '../profile_icon.svg';

const Profile = () => {
    const [user, setUser] = useContext(UserContext);

    const logout = () => {
        localStorage.removeItem('auth');
        setUser(null);
    }

    return (
        <div className={`${styles.profile} d-inline-flex flex-row align-items-center`}>
            {user ?
                <div className={`${styles.loggedIn} d-flex flex-row`}>
                    <div className={`${styles.assets} d-flex flex-column`}>
                        <p className={`${styles.assetsText}`}>Razpoložljiva sredstva:</p>
                        <div className={`${styles.assetsAmmount} d-flex flex-row align-items-center`}>
                            <p className={`${styles.assetsAmmountPrice} me-2`}>{integerToCurrency((MAX_ALLOWED_RESOURCES - user.price))} €</p>
                                <Link to="/profile" className={`${styles.assetsUrl}`}>Preglej</Link>
                        </div>
                    </div>
                    <div className={`${styles.currentUser} ms-5 d-flex flex-row align-items-center`}>
                        <div className={`${styles.currentUserInfo}`}>
                            <p className={`mb-0`}>{user.name}</p>
                            <Link onClick={logout} className={`${styles.currentUserLogout}`}>Odjava</Link>
                        </div>
                        <div className={`${styles.currentUserIcon} d-flex justify-content-center align-items-center ms-2`}>
                            {user.role === 1 || user.role === 2 ?
                                <Link to="/admin/dashboard"><img src={profile_icon} alt=""/></Link>
                                :
                                <img src={profile_icon} alt=""/>
                            }

                        </div>
                    </div>
                </div>
                :

                <div className={`${styles.notLoggedIn}`}>
                    <Link to="/adminlogin" className={`btn btn-simple-login`}>Prijavi se</Link>
                    <Link to="/register" className={`btn btn-primary-login`}>Registriraj se</Link>
                </div>
            }
        </div>
    )
}

export default Profile;