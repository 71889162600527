import {useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import axios from "axios";
import style from './AdminProject.module.css';
import {integerToCurrency} from "../../../utils";
import Swal from "sweetalert2";
import location_icon from './location_icon.svg';
import dayjs from "dayjs";

const AdminProject = () => {
    const navigate = useNavigate();
    const params = useParams();
    const [project, setProject] = useState();
    const [loaded, setLoaded] = useState(false);
    const [requresAdditionalInfo, setRequiresAdditionalInfo] = useState(false);
    const [evalvation, setEvalvation] = useState({
        question1: false,
        question2: false,
        question3: false,
        question4: false,
        question5: false,
        question6: false,
        question7: false,
        question8: false,
        question9: false,
    })
    const [selectedState, setSelectedState] = useState('');

    function handleSelectChange(event) {
        setSelectedState(event.target.value);
    }
    /*function displaySelectedItems(state) {
        console.log(state);
    }*/

    useEffect(() => {
        let userData = JSON.parse(localStorage.getItem('auth'));
        console.log(process.env.REACT_APP_BASE_URL + '/projects/' + params.id)
        axios.get(process.env.REACT_APP_BASE_URL + '/projects/' + params.id, {
            headers: {
                'Authorization': 'Bearer ' + userData.token
            }
        })
            .then(res => {
                setProject(res.data);
                setLoaded(true);
            })
    }, []);

    const handleEvaluationChange = (e) => {
        setEvalvation((prevState) => {
            if(e.target.value === "true") {
                return {...prevState, [e.target.name]: true};
            } else {
                return {...prevState, [e.target.name]: false};
            }

        })
    }

    const rejectProject = (projectId) => {
        let userData = JSON.parse(localStorage.getItem('auth'));
        axios.post(process.env.REACT_APP_BASE_URL + '/projects/' + params.id + '/reject', '', {
            headers: {
                'Authorization': 'Bearer ' + userData.token
            }
        })
            .then(res => {
                return navigate('/admin/dashboard');
            })
    }

    const requireActionProject = (projectId) => {
        let userData = JSON.parse(localStorage.getItem('auth'));
        axios.post(process.env.REACT_APP_BASE_URL + '/projects/' + params.id + '/action', '', {
            headers: {
                'Authorization': 'Bearer ' + userData.token
            }
        })
            .then(res => {
                return navigate('/admin/dashboard');
            })
    }

    const requireActionProjectShow = () => {
        setRequiresAdditionalInfo(true);
    }

    const approveProject = (projectId) => {
        let userData = JSON.parse(localStorage.getItem('auth'));

        if(
            evalvation.question1 !== true ||
            evalvation.question2 !== true ||
            evalvation.question3 !== true ||
            evalvation.question4 !== true ||
            evalvation.question5 !== true ||
            evalvation.question6 !== true ||
            evalvation.question7 !== true ||
            evalvation.question8 !== true ||
            evalvation.question9 !== true
        ) {
            Swal.fire({
                title: "Napaka",
                text: "Evalvacija projeta ni opravljena",
                icon: "error"
            });

            return;
        }

        axios.post(process.env.REACT_APP_BASE_URL + '/projects/' + params.id + '/approve', '', {
            headers: {
                'Authorization': 'Bearer ' + userData.token
            }
        })
        .then(res => {
            Swal.fire({
                title: "Evalvacija uspešna",
                text: "Evalvacija je bila uspešno zabeležena.",
                icon: "success"

            }).then(
                function (){
                    return navigate('/admin/dashboard');
                }
            )
        })
    }


    const votedProject = (projectId) => {
        let userData = JSON.parse(localStorage.getItem('auth'));
        axios.post(process.env.REACT_APP_BASE_URL + '/projects/' + params.id + '/voted', '', {
            headers: {
                'Authorization': 'Bearer ' + userData.token
            }
        })
            .then(res => {
                return navigate('/admin/dashboard');
            })
    }

    const inRealization = (projectId) => {
        let userData = JSON.parse(localStorage.getItem('auth'));
        axios.post(process.env.REACT_APP_BASE_URL + '/projects/' + params.id + '/realization', '', {
            headers: {
                'Authorization': 'Bearer ' + userData.token
            }
        })
            .then(res => {
                return navigate('/admin/dashboard');
            })
    }

    const completed = (projectId) => {
        let userData = JSON.parse(localStorage.getItem('auth'));
        axios.post(process.env.REACT_APP_BASE_URL + '/projects/' + params.id + '/completed', '', {
            headers: {
                'Authorization': 'Bearer ' + userData.token
            }
        })
            .then(res => {
                return navigate('/admin/dashboard');
            })
    }

    return (
        loaded ?
            <div className={`container`}>
                {project.images.length > 0 ?
                    <div className={`${style.projectHeader} d-flex flex-column justify-content-end`}
                         style={{backgroundImage: 'url(' + process.env.REACT_APP_BASE_STORAGE + '/' + project.images[0].path}}>
                        <h5 className={`${style.dummyText}`}>Preglej vse pretekle proračune</h5>
                        <h1 className={`${style.projectHeaderTitle}`}>{project.title}</h1>
                        {project.user ?
                            <p className={`${style.projectHeaderNameNoImage}`}>{project.user.name}, {project.user.email}, {dayjs(project.created_at).format('D.M.YYYY')}</p>
                        :
                            <p className={`${style.projectHeaderNameNoImage}`}>{project.name_surname}</p>
                        }
                        <span className={`${style.projectLocation}`}>
                            <img className={`${style.location_icon}`} src={location_icon} alt=""/>
                            {project.area}
                        </span>
                        <div className={`${style.zamakniCenoDolLevo}`}>
                            <div className={`${style.projectWorthWrapper}`}>
                                <p className={`mb-0 ${style.projectWorthTitle}`}>Vrednost projekta</p>
                                <p className={`mb-0 ${style.projectWorthAmount}`}>{integerToCurrency(project.project_cost)} EUR</p>
                            </div>
                        </div>
                    </div>
                :
                    <div className={`${style.projectHeaderNoImage}`}>
                        <h1 className={`${style.projectHeaderTitleNoImage}`}>{project.title}</h1>
                        {project.user ?
                            <p className={`${style.projectHeaderNameNoImage}`}>{project.user.name}</p>
                        :
                            <p className={`${style.projectHeaderNameNoImage}`}>{project.name_surname}</p>
                        }
                    </div>
                }
                <div className={`${style.zamakniVDesno}`}>
                    <p className={`mt-4 mb-4 ${style.detailedDescription}`}>{project.detailed_description}</p>
                    <div className={`${style.questions}`}>
                        <div className={`${style.question} mb-2`}>
                            <h2 className={`${style.questionTitle}`}>
                                Zakaj ta predlog? katero temo, težavo oz. izziv projektni predlog naslavlja?
                            </h2>
                            <p className={`${style.questionValue} mb-0`}>{project.question1}</p>
                        </div>

                        <div className={`${style.question} mb-2`}>
                            <h2 className={`${style.questionTitle}`}>
                                Kaj konkretno predlagate? Kaj je bistvo vašega predloga? Kaj se bo zgodilo?
                            </h2>
                            <p className={`${style.questionValue} mb-0`}>{project.question2}</p>
                        </div>

                        <div className={`${style.question} mb-2`}>
                            <h2 className={`${style.questionTitle}`}>
                                Kako naj bi se projekt izvedel? Katere aktivnosti bi bilo treba izpeljati?
                            </h2>
                            <p className={`${style.questionValue} mb-0`}>{project.question3}</p>
                        </div>

                        <div className={`${style.question} mb-2`}>
                            <h2 className={`${style.questionTitle}`}>
                                Kje konkretno naj bi se projekt izvajal?
                            </h2>
                            <p className={`${style.questionValue} mb-0`}>{project.question4}</p>
                        </div>

                        <div className={`${style.question} mb-2`}>
                            <h2 className={`${style.questionTitle}`}>
                                Časovni okvir: kdaj naj bi se projekt izvedel?
                            </h2>
                            <p className={`${style.questionValue} mb-0`}>{project.question5}</p>
                        </div>

                        <div className={`${style.question} mb-2`}>
                            <h2 className={`${style.questionTitle}`}>
                                Kaj bi se po izvedbi projekta spremenilo, izboljšalo?
                            </h2>
                            <p className={`${style.questionValue} mb-0`}>{project.question6}</p>
                        </div>
                    </div>
                    <div className={`files mt-5`}>
                        <h3>Dokumenti projekta</h3>
                        {project.documents?.map((document) => {
                            return (
                                <div className={`document`}>
                                    <a target="_blank" href={`${process.env.REACT_APP_BASE_STORAGE}/` + document.path}
                                       className={`${style.documentLink}`}>{document.name}</a>
                                </div>
                            )
                        })}
                    </div>
                    <div className={`${style.evalvation} mt-5`}>
                        <h3 className={`${style.projectEvaluationTitle} mt-5`}>Evalvacija predloga</h3>
                        <h5 className={`${style.projectEvaluationText} mt-4`}>
                            Prispeva k uresničevanju javnega interesa in se izvaja na območju Mestne občine Koper?
                        </h5>
                        {/*<label className={`${style.radioButtonLabel} radio-inline`}>
                            <input type="radio" className={`${style.radioButtonInput}`} name="question1" value="true"
                                   checked={evalvation.question1 === true} onChange={handleEvaluationChange}/>
                            DA
                        </label>
                        <label className={`${style.radioButtonLabel} radio-inline`}>
                            <input type="radio" className={`${style.radioButtonInput}`} name="question1" value="false"
                                   checked={evalvation.question1 === false} onChange={handleEvaluationChange}/>
                            NE
                        </label>*/}
                        <div className={`${style.ananas}`}>
                            <label className={`${style.item} `}>
                                <input type="radio" value="true" name="question1" required="required"
                                       checked={evalvation.question1 === true} onChange={handleEvaluationChange}/>
                                <div className={`${style.radio_image}`}></div>
                                DA
                            </label>
                            <label className={`${style.item} `}>
                                <input type="radio" value="false" name="question1" required="required"
                                       checked={evalvation.question1 === false} onChange={handleEvaluationChange}/>
                                <div className={`${style.radio_image}`}></div>
                                NE
                            </label>
                        </div>

                        <h5 className={`${style.projectEvaluationText} mt-4`}>
                            Prispeva k namenom poziva — spodbuja aktivno participacijo in vključenost občanov, dviga
                            kakovost bivanja v lokalnem okolju, gradi skupnostni pristop in je namenjen splošni javni
                            uporabi?
                        </h5>
                        {/*<label className={`${style.radioButtonLabel} radio-inline`}>
                            <input type="radio" className={`${style.radioButtonInput}`} name="question2" value="true"
                                   checked={evalvation.question2 === true} onChange={handleEvaluationChange}/>
                            DA
                        </label>
                        <label className={`${style.radioButtonLabel} radio-inline`}>
                            <input type="radio" className={`${style.radioButtonInput}`} name="question2" value="false"
                                   checked={evalvation.question2 === false} onChange={handleEvaluationChange}/>
                            NE
                        </label>*/}
                        <div className={`${style.ananas}`}>
                            <label className={`${style.item} `}>
                                <input type="radio" value="true" name="question2" required="required"
                                       checked={evalvation.question2 === true} onChange={handleEvaluationChange}/>
                                <div className={`${style.radio_image}`}></div>
                                DA
                            </label>
                            <label className={`${style.item} `}>
                                <input type="radio" value="false" name="question2" required="required"
                                       checked={evalvation.question2 === false} onChange={handleEvaluationChange}/>
                                <div className={`${style.radio_image}`}></div>
                                NE
                            </label>
                        </div>

                        <h5 className={`${style.projectEvaluationText} mt-4`}>
                            Je takšne narave, da ga bo Mestna občina Koper lahko izpeljala v okviru občinskih
                            zmogljivosti in zakonskih pristojnosti?
                        </h5>
                        {/*<label className={`${style.radioButtonLabel} radio-inline`}>
                            <input type="radio" className={`${style.radioButtonInput}`} name="question3" value="true"
                                   checked={evalvation.question3 === true} onChange={handleEvaluationChange}/>
                            DA
                        </label>
                        <label className={`${style.radioButtonLabel} radio-inline`}>
                            <input type="radio" className={`${style.radioButtonInput}`} name="question3" value="false"
                                   checked={evalvation.question3 === false} onChange={handleEvaluationChange}/>
                            NE
                        </label>*/}
                        <div className={`${style.ananas}`}>
                            <label className={`${style.item} `}>
                                <input type="radio" value="true" name="question3" required="required"
                                       checked={evalvation.question3 === true} onChange={handleEvaluationChange}/>
                                <div className={`${style.radio_image}`}></div>
                                DA
                            </label>
                            <label className={`${style.item} `}>
                                <input type="radio" value="false" name="question3" required="required"
                                       checked={evalvation.question3 === false} onChange={handleEvaluationChange}/>
                                <div className={`${style.radio_image}`}></div>
                                NE
                            </label>
                        </div>

                        <h5 className={`${style.projectEvaluationText} mt-4`}>
                            Ni uvrščen v proračun oz. načrt razvojnih programov občinskih vlaganj po krajevnih
                            skupnostih in ne ustreza pogojem za nepovratna finančna sredstva, ki jih predlagatelji lahko
                            pridobijo iz javnih razpisov Mestne občine Koper?
                        </h5>
                        {/*<label className={`${style.radioButtonLabel} radio-inline`}>
                            <input type="radio" className={`${style.radioButtonInput}`} name="question4" value="true"
                                   checked={evalvation.question4 === true} onChange={handleEvaluationChange}/>
                            DA
                        </label>
                        <label className={`${style.radioButtonLabel} radio-inline`}>
                            <input type="radio" className={`${style.radioButtonInput}`} name="question4" value="false"
                                   checked={evalvation.question4 === false} onChange={handleEvaluationChange}/>
                            NE
                        </label>*/}
                        <div className={`${style.ananas}`}>
                            <label className={`${style.item} `}>
                                <input type="radio" value="true" name="question4" required="required"
                                       checked={evalvation.question4 === true} onChange={handleEvaluationChange}/>
                                <div className={`${style.radio_image}`}></div>
                                DA
                            </label>
                            <label className={`${style.item} `}>
                                <input type="radio" value="false" name="question4" required="required"
                                       checked={evalvation.question4 === false} onChange={handleEvaluationChange}/>
                                <div className={`${style.radio_image}`}></div>
                                NE
                            </label>
                        </div>

                        <h5 className={`${style.projectEvaluationText} mt-4`}>
                            Je skladen z zakonodajo in veljavnimi občinskimi akti?
                        </h5>
                        {/*<label className={`${style.radioButtonLabel} radio-inline`}>
                            <input type="radio" className={`${style.radioButtonInput}`} name="question5" value="true"
                                   checked={evalvation.question5 === true} onChange={handleEvaluationChange}/>
                            DA
                        </label>
                        <label className={`${style.radioButtonLabel} radio-inline`}>
                            <input type="radio" className={`${style.radioButtonInput}`} name="question5" value="false"
                                   checked={evalvation.question5 === false} onChange={handleEvaluationChange}/>
                            NE
                        </label>*/}
                        <div className={`${style.ananas}`}>
                            <label className={`${style.item} `}>
                                <input type="radio" value="true" name="question5" required="required"
                                       checked={evalvation.question5 === true} onChange={handleEvaluationChange}/>
                                <div className={`${style.radio_image}`}></div>
                                DA
                            </label>
                            <label className={`${style.item} `}>
                                <input type="radio" value="false" name="question5" required="required"
                                       checked={evalvation.question5 === false} onChange={handleEvaluationChange}/>
                                <div className={`${style.radio_image}`}></div>
                                NE
                            </label>
                        </div>

                        <h5 className={`${style.projectEvaluationText} mt-4`}>
                            V preteklih petih letih ni bil financiran/sofinanciran iz proračuna Mestne občine Koper?
                        </h5>
                        {/*<label className={`${style.radioButtonLabel} radio-inline`}>
                            <input type="radio" className={`${style.radioButtonInput}`} name="question6" value="true"
                                   checked={evalvation.question6 === true} onChange={handleEvaluationChange}/>
                            DA
                        </label>
                        <label className={`${style.radioButtonLabel} radio-inline`}>
                            <input type="radio" className={`${style.radioButtonInput}`} name="question6" value="false"
                                   checked={evalvation.question6 === false} onChange={handleEvaluationChange}/>
                            NE
                        </label>*/}
                        <div className={`${style.ananas}`}>
                            <label className={`${style.item} `}>
                                <input type="radio" value="true" name="question6" required="required"
                                       checked={evalvation.question6 === true} onChange={handleEvaluationChange}/>
                                <div className={`${style.radio_image}`}></div>
                                DA
                            </label>
                            <label className={`${style.item} `}>
                                <input type="radio" value="false" name="question6" required="required"
                                       checked={evalvation.question6 === false} onChange={handleEvaluationChange}/>
                                <div className={`${style.radio_image}`}></div>
                                NE
                            </label>
                        </div>

                        <h5 className={`${style.projectEvaluationText} mt-4`}>
                            Je finančno ovrednoten med 10.000 in 40.000 evri z vključenim DDV-jem?
                        </h5>
                        {/*<label className={`${style.radioButtonLabel} radio-inline`}>
                            <input type="radio" className={`${style.radioButtonInput}`} name="question7" value="true"
                                   checked={evalvation.question7 === true} onChange={handleEvaluationChange}/>
                            DA
                        </label>
                        <label className={`${style.radioButtonLabel} radio-inline`}>
                            <input type="radio" className={`${style.radioButtonInput}`} name="question7" value="false"
                                   checked={evalvation.question7 === false} onChange={handleEvaluationChange}/>
                            NE
                        </label>*/}
                        <div className={`${style.ananas}`}>
                            <label className={`${style.item} `}>
                                <input type="radio" value="true" name="question7" required="required"
                                       checked={evalvation.question7 === true} onChange={handleEvaluationChange}/>
                                <div className={`${style.radio_image}`}></div>
                                DA
                            </label>
                            <label className={`${style.item} `}>
                                <input type="radio" value="false" name="question7" required="required"
                                       checked={evalvation.question7 === false} onChange={handleEvaluationChange}/>
                                <div className={`${style.radio_image}`}></div>
                                NE
                            </label>
                        </div>

                        <h5 className={`${style.projectEvaluationText} mt-4`}>
                            Je realno izvedljiv v proračunskem letu 2023 oz. 2024?
                        </h5>
                        {/*<label className={`${style.radioButtonLabel} radio-inline`}>
                            <input type="radio" className={`${style.radioButtonInput}`} name="question8" value="true"
                                   checked={evalvation.question8 === true} onChange={handleEvaluationChange}/>
                            DA
                        </label>
                        <label className={`${style.radioButtonLabel} radio-inline`}>
                            <input type="radio" className={`${style.radioButtonInput}`} name="question8" value="false"
                                   checked={evalvation.question8 === false} onChange={handleEvaluationChange}/>
                            NE
                        </label>*/}
                        <div className={`${style.ananas}`}>
                            <label className={`${style.item} `}>
                                <input type="radio" value="true" name="question8" required="required"
                                       checked={evalvation.question8 === true} onChange={handleEvaluationChange}/>
                                <div className={`${style.radio_image}`}></div>
                                DA
                            </label>
                            <label className={`${style.item} `}>
                                <input type="radio" value="false" name="question8" required="required"
                                       checked={evalvation.question8 === false} onChange={handleEvaluationChange}/>
                                <div className={`${style.radio_image}`}></div>
                                NE
                            </label>
                        </div>

                        <h5 className={`${style.projectEvaluationText} mt-4`}>
                            Je umeščen v enega od šestih območij občine, opredeljenih za participativni proračun?
                        </h5>
                        {/*<label className={`${style.radioButtonLabel} radio-inline`}>
                            <input type="radio" className={`${style.radioButtonInput}`} name="question9" value="true"
                                   checked={evalvation.question9 === true} onChange={handleEvaluationChange}/>
                            DA
                        </label>
                        <label className={`${style.radioButtonLabel} radio-inline`}>
                            <input type="radio" className={`${style.radioButtonInput}`} name="question9" value="false"
                                   checked={evalvation.question9 === false} onChange={handleEvaluationChange}/>
                            NE
                        </label>*/}
                        <div className={`${style.ananas}`}>
                            <label className={`${style.item} `}>
                                <input type="radio" value="true" name="question9" required="required"
                                       checked={evalvation.question9 === true} onChange={handleEvaluationChange}/>
                                <div className={`${style.radio_image}`}></div>
                                DA
                            </label>
                            <label className={`${style.item} `}>
                                <input type="radio" value="false" name="question9" required="required"
                                       checked={evalvation.question9 === false} onChange={handleEvaluationChange}/>
                                <div className={`${style.radio_image}`}></div>
                                NE
                            </label>
                        </div>

                        <h3 className={`${style.projectStatusTitle}`}>Status predloga</h3>
                        <div className="input-group">
                            <select placeholder="Vsa območja" className={`${style.customSelect}`} value={selectedState}
                                    onChange={handleSelectChange}>
                                <option value="">Vsa območja</option>
                                <option value="Območje 1">Območje 1</option>
                                <option value="Območje 2">Območje 2</option>
                                <option value="Območje 3">Območje 3</option>
                                <option value="Območje 4">Območje 4</option>
                                <option value="Območje 5">Območje 5</option>
                                <option value="Območje 6">Območje 6</option>
                            </select>
                            <button
                                className={`${style.spremeniStatusGumb}`}>{selectedState !== '' ? selectedState : 'Spremeni status'}</button>
                        </div>
                        {/*
                        {project.status !== 'Rejected' && project.status !== 'Completed' ?
                            <Dropdown>
                                <Dropdown.Toggle className={`${style.abc} btn-outline-secondary`} id="dropdown-basic">
                                    Status
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    {project.status === 'Unchecked' ?
                                        <>
                                            <Dropdown.Item onClick={() => {
                                                rejectProject(project.id)
                                            }}>
                                                Zavrni projekt
                                            </Dropdown.Item>
                                            <Dropdown.Item onClick={requireActionProjectShow}>
                                                Zahtevaj dopolnila
                                            </Dropdown.Item>
                                            <Dropdown.Item onClick={() => {
                                                approveProject(project.id)
                                            }}>
                                                Potrdi kot usrezen projekt
                                            </Dropdown.Item>
                                        </>
                                        :
                                        ''
                                    }

                                    {project.status === 'Requires action' ?
                                        <>
                                            <Dropdown.Item onClick={() => {
                                                rejectProject(project.id)
                                            }}>
                                                Zavrni projekt
                                            </Dropdown.Item>
                                            <Dropdown.Item onClick={() => {
                                                approveProject(project.id)
                                            }}>
                                                Potrdi kot usrezen projekt
                                            </Dropdown.Item>
                                        </>
                                        :
                                        ''
                                    }

                                    {project.status === 'Approved' ?
                                        <>
                                            <Dropdown.Item onClick={() => {
                                                votedProject(project.id)
                                            }}>
                                                Označi kot izglasovan
                                            </Dropdown.Item>
                                        </>
                                        :
                                        ''
                                    }

                                    {project.status === 'Voted' ?
                                        <>
                                            <Dropdown.Item onClick={() => {
                                                inRealization(project.id)
                                            }}>
                                                Označi kot projekt v izvajanju
                                            </Dropdown.Item>
                                        </>
                                        :
                                        ''
                                    }

                                    {project.status === 'In realization' ?
                                        <>
                                            <Dropdown.Item onClick={() => {
                                                completed(project.id)
                                            }}>
                                                Označi projekt kot zaključen
                                            </Dropdown.Item>
                                        </>
                                        :
                                        ''
                                    }
                                </Dropdown.Menu>
                            </Dropdown>
                            :
                            ''
                        }
                        {requresAdditionalInfo ?
                            <div>
                                <p>test</p>
                            </div>
                            :
                            ''
                        }
*/}
                    </div>
                    {/*<button onClick={saveRadioButtonState}>submit</button>*/}
                    <button onClick={approveProject}>submit</button>
                </div>
            </div>
            :
            ''

    )
}

export default AdminProject;