import styles from './AboutParticipatoryBudgeting.module.css';
import {Link} from "react-router-dom";
import one from './1.svg';
import two from './2.svg';
import three from './3.svg';
import four from './4.svg';
import five from './5.svg';

import step1 from './step1.svg';
import step2 from './step2.svg';
import step3 from './step3.svg';
import step4 from './step4.svg';
import step5 from './step5.svg';
import PreviousProjects from "../Parts/PreviousProjects/PreviousProjects";
import React, {useEffect, useState} from "react";
import axios from "axios";


const AboutParticipatoryBudgeting = () => {
    const [activeBudgetPhase, setActiveBudgetPhase] = React.useState(null);


    useEffect(() => {
        let userData = JSON.parse(localStorage.getItem('auth'));
        axios.get(process.env.REACT_APP_BASE_URL + '/getActiveBudgetPhase', {
            headers: {
                'Authorization': 'Bearer ' + userData.token
            }
        })
            .then(res => {
                setActiveBudgetPhase(res.data)
                console.log(activeBudgetPhase);
            })
    }, []);

    return (
        <div className={`home`}>
            <div className={`${styles.howToParticipateWrapper}`}>
                <div className="container">
                    <h2 className={`${styles.howToParticipateTitle}`}>Praticipativni proračun 23/24</h2>
                    <div className={`${styles.howToParticipateSteps}`}>
                        <div className={`${styles.howToParticipateStepRow} row`}>

                            {/* first block in Participativni proracun row */}
                            <div className={`${styles.howToParticipateStepWrapper} col-12 col-lg-4 d-flex flex-row`}>
                                <div className="img-wrapper">
                                    <img src={step1} alt=""/>
                                </div>
                                <div className={`${styles.howToParticipateStepContent}`}>
                                    <h2 className={`${styles.howToParticipateStepsTitle}`}>Predlagaj nov projekt</h2>
                                    <p className={`${styles.howToParticipateStepsText} m-0`}>10. JUN - 11. JUL 2023</p>
                                    {activeBudgetPhase === 0 ?
                                        <Link to='/projects' className={`btn ${styles.btn_primary_big_ovverride}`}>Spremljaj</Link>
                                    :
                                        <Link to='/projects' className={`${styles.howToParticipateReadMore}`}>Preberi več</Link>
                                    }
                                </div>
                            </div>

                            {/* second block in Participativni proracun row */}
                            <div className={`${styles.howToParticipateStepWrapper} col-12 col-lg-4 d-flex flex-row`}>
                                <div className="img-wrapper">
                                    <img src={step3} alt=""/>
                                </div>
                                <div className={`${styles.howToParticipateStepContent}`}>
                                    <h2 className={`${styles.howToParticipateStepsTitle}`}>Glasuj za najboljše</h2>
                                    <p className={`${styles.howToParticipateStepsText} m-0`}>12. SEP - 20. SEP 2023</p>
                                    {activeBudgetPhase === 2 ?
                                        <Link to='/projects' className={`btn ${styles.btn_primary_big_ovverride}`}>Spremljaj</Link>
                                    :
                                        <Link to='/projects' className={`${styles.howToParticipateReadMore}`}>Preberi več</Link>
                                    }
                                </div>
                            </div>

                            {/* third block in Participativni proracun row */}
                            <div className={`${styles.howToParticipateStepWrapper} col-12 col-lg-4 d-flex flex-row`}>
                                <div className="img-wrapper">
                                    <img src={step5} alt=""/>
                                </div>
                                <div className={`${styles.howToParticipateStepContent}`}>
                                    <h2 className={`${styles.howToParticipateStepsTitle}`}>Spremljaj izvedbo</h2>
                                    <p className={`${styles.howToParticipateStepsText} m-0`}>10. JUN - 11. JUL 2023</p>
                                    {activeBudgetPhase === 4 ?
                                        <Link to='/projects' className={`btn ${styles.btn_primary_big_ovverride}`}>Spremljaj</Link>
                                    :
                                        <Link to='/projects' className={`${styles.howToParticipateReadMore}`}>Preberi več</Link>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Kaj je participativni proracun */}
            <div className="container">
                <div className={`${styles.whatIsParticipateWrapper}`}>
                    <div className="col-12 col-lg-12 d-flex flex-column">
                        <h1 className={`${styles.howToParticipateTitle}`}>Kaj je participativni proračun?</h1>
                    </div>
                    <div className="col-12 col-lg-6 d-flex flex-column">
                        <p className={`${styles.whatIsParticipateDescription}`}>Participativni proračun je sistem razporejanja dela proračunskega denarja tako, da o njegovi porabi soodločajo občanke in občani. Sami predlagajo projekte in določijo, kateri so najnujnejši in bi največ prispevali k izboljšanju kakovosti bivanja v skupnosti.</p>
                    </div>
                </div>
            </div>

            {/* Kako deluje? */}
            <div className="container">
                <div className={`${styles.kakoDelujeWrapper}`}>
                    <div className={`${styles.kakoDelujeLeftContent}`}>
                        <h1 className={`${styles.kakoDelujeTitle}`}>Kako deluje?</h1>
                        <div className={`${styles.howToParticipateSteps}`}>
                            {/* 1. elemant */}
                            <div className={`${styles.howToParticipateStepRow} row`}>
                                <div
                                    className={`${styles.howToParticipateStepWrapper} col-12 col-lg-12 d-flex flex-row`}>
                                    <div className="img-wrapper">
                                        <img src={step1} alt=""/>
                                    </div>
                                    <div className={`${styles.howToParticipateStepContent}`}>
                                        <h2 className={`${styles.howToParticipateStepsTitle}`}>Zbiranje predlogov</h2>
                                        <p className={`${styles.howToParticipateStepsText}`}>
                                            Občanke in občani predloge projektov oddajo med 10. 6. in 11. 7. 2022.
                                            Posamezni predlog projekta mora biti v skladu s predpisanimi pogoji, njegova
                                            vrednost pa ne sme biti nižja od 10.000 in višja od 40.000 €. Predlog
                                            projekta lahko oddajo prek spleta, elektronske pošte ali na obrazcu v
                                            vložišču MOK ali na sedežih krajevnih skupnosti.
                                        </p>
                                        {activeBudgetPhase === 0 ?
                                            <Link to='/projects' className={`${styles.howToParticipateReadMore}`}>Preberi več</Link>
                                        :
                                            ''
                                        }
                                    </div>
                                    <div className={`${styles.kakoDelujeNumbers}`}>
                                        <img src={one} alt=""/>
                                    </div>
                                </div>
                            </div>

                            <br/>
                            {/* 2. elemant */}
                            <div className={`${styles.howToParticipateStepRow} row`}>
                                <div
                                    className={`${styles.howToParticipateStepWrapper} col-12 col-lg-12 d-flex flex-row`}>
                                    <div className="img-wrapper">
                                        <img src={step2} alt=""/>
                                    </div>
                                    <div className={`${styles.howToParticipateStepContent}`}>
                                        <h2 className={`${styles.howToParticipateStepsTitle}`}>Pregled predlogov</h2>
                                        <p className={`${styles.howToParticipateStepsText}`}>
                                            Vse oddane predloge projektov pregleda komisija, jih finančno ovrednoti in
                                            preveri, ali so izvedljivi, skladni z zakonodajo, znotraj geografskega
                                            območja in časovnice izvajanja participativnega proračuna ter v skladu s
                                            pravili o vrednosti predlogov in drugimi predpisanimi pogoji. Komisija lahko
                                            podobne predloge združi.
                                        </p>
                                        {activeBudgetPhase === 1 ?
                                            <Link to='/projects' className={`${styles.howToParticipateReadMore}`}>Preberi več</Link>
                                        :
                                            ''
                                        }
                                    </div>
                                    <div className={`${styles.kakoDelujeNumbersDva}`}>
                                        <img src={two} alt=""/>
                                    </div>
                                </div>
                            </div>
                            <br/>
                            {/* 3. elemant */}
                            <div className={`${styles.howToParticipateStepRow} row`}>
                                <div
                                    className={`${styles.howToParticipateStepWrapper} col-12 col-lg-12 d-flex flex-row`}>
                                    <div className="img-wrapper">
                                        <img src={step3} alt=""/>
                                    </div>
                                    <div className={`${styles.howToParticipateStepContent}`}>
                                        <h2 className={`${styles.howToParticipateStepsTitle}`}>Glasovanje</h2>
                                        <p className={`${styles.howToParticipateStepsText}`}>
                                            Glasovanje bo potekalo med 12. in 20. 9. 2022 prek spleta ali v prostorih Mestne občine Koper in na sedežih krajevnih skupnosti. Sodeluje lahko vsaka občanka ali občan s stalnim bivališčem v Mestni občini Koper, ki je dopolnil/-a 15 let. Glasuje se tako, da občan/-ka v izbranem območju razdeli predvidenih 160.000 € sredstev, namenjenih območju, med projekte, za katere si želi, da bi bili izvedeni.
                                        </p>
                                        {activeBudgetPhase === 2 ?
                                            <Link to='/projects' className={`${styles.howToParticipateReadMore}`}>Preberi več</Link>
                                        :
                                            ''
                                        }
                                    </div>
                                    <div className={`${styles.kakoDelujeNumbersTri}`}>
                                        <img src={three} alt=""/>
                                    </div>
                                </div>
                            </div>
                            <br/>
                            {/* 4. elemant */}
                            <div className={`${styles.howToParticipateStepRow} row`}>
                                <div
                                    className={`${styles.howToParticipateStepWrapper} col-12 col-lg-12 d-flex flex-row`}>
                                    <div className="img-wrapper">
                                        <img src={step4} alt=""/>
                                    </div>
                                    <div className={`${styles.howToParticipateStepContent}`}>
                                        <h2 className={`${styles.howToParticipateStepsTitle}`}>Razglasitev rezultatov</h2>
                                        <p className={`${styles.howToParticipateStepsText}`}>
                                            Po končanem glasovanju bo občina preverila rezultate in razglasila izglasovane projekte. Izglasovane projekte bo župan vključil v predlog proračuna Mestne občine Koper za leti 2023 in 2024.
                                        </p>
                                        {activeBudgetPhase === 3 ?
                                            <Link to='/projects' className={`${styles.howToParticipateReadMore}`}>Preberi več</Link>
                                        :
                                            ''
                                        }
                                    </div>
                                    <div className={`${styles.kakoDelujeNumbersTri}`}>
                                        <img src={four} alt=""/>
                                    </div>
                                </div>
                            </div>
                            <br/>
                            {/* 5. elemant */}
                            <div className={`${styles.howToParticipateStepRow} row`}>
                                <div
                                    className={`${styles.howToParticipateStepWrapper} col-12 col-lg-12 d-flex flex-row`}>
                                    <div className="img-wrapper">
                                        <img src={step5} alt=""/>
                                    </div>
                                    <div className={`${styles.howToParticipateStepContent}`}>
                                        <h2 className={`${styles.howToParticipateStepsTitle}`}>Spremljanje izvedbo</h2>
                                        <p className={`${styles.howToParticipateStepsText}`}>
                                            Vsi izglasovani projekti bodo izvedeni v letih 2023 in 2024. V tej fazi participativnega proračuna bomo skrbeli za ažurno obveščanje o napredovanju izvedbe projektov in sprotno posodabljanje njihovega statusa.
                                        </p>
                                        {activeBudgetPhase === 4 ?
                                            <Link to='/projects' className={`${styles.howToParticipateReadMore}`}>Preberi več</Link>
                                        :
                                            ''
                                        }
                                    </div>
                                    <div className={`${styles.kakoDelujeNumbersTri}`}>
                                        <img src={five} alt=""/>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <PreviousProjects style={{marginTop: '100px'}}/>
        </div>
    )
}

export default AboutParticipatoryBudgeting;