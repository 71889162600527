import {Link, Navigate, Outlet, useNavigate} from "react-router-dom";
import logo from './logo.png';
import access_icon from './access_icon.svg';
import styles from './Layout.module.css';
import { useContext } from "react";
import UserContext from "../Contexts/user.context";
import Profile from "./Profile/Profile";
import {isLoggedIn} from "../../utils/authUtils";
import mok_logo from "./mok-logo.svg";

const ProtectedLayout = () => {
    const [user, setUser] = useContext(UserContext);
    const navigate = useNavigate();
    const loggedIn = isLoggedIn();

    const logout = () => {
        localStorage.removeItem('auth');
        setUser(null);
        return navigate('/');
    }
    return (
        loggedIn ?
            <div className="main">
                <div className={`${styles.header} container d-flex flex-row justify-content-between`}>
                    <div className="logo-access d-flex flex-row align-items-center">
                        <Link to="/"><img className={styles.logo} src={logo} alt="Page logo" /></Link>
                        <img className={styles.access_icon} src={access_icon} alt="Accessibility toggle" />
                        <div className={`${styles.lang_switcher}`}>
                            <span>Italiano</span>
                        </div>
                    </div>
                    <Profile />
                </div>
                <div className="content">
                    <Outlet />
                </div>
                <div className={`${styles.footer}`}>
                    <div className="container">
                        <div className={`${styles.footerContent} d-flex flex-row justify-content-between align-items-center`}>
                            <img src={mok_logo} alt=""/>
                            <div className={`${styles.links}`}>
                                <Link to='/' className={`${styles.link}`}>O participativnem proračunu</Link>
                                <Link to='/' className={`${styles.link} ms-3`}>Podpora uporabnikom</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div> : <Navigate to={'/login'}/>
    )
}

export default ProtectedLayout;