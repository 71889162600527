import axios from 'axios';
import React, {useEffect, useState} from "react";
import styles from './Suggest.module.css';
import {Link} from "react-router-dom";
import zarnica from './zarnica.svg';
import paper from './paper.svg';
import PreviousProjects from "../Parts/PreviousProjects/PreviousProjects";

const Suggest = () => {


    /*console.log(post);*/

    return (
        <div className={`home`}>
            <div className={`${styles.howToParticipateWrapper}`}>
                <div className="container">
                    <div className={`${styles.howToParticipateSteps}`}>
                        <div className={`${styles.howToParticipateStepRow} row`}>
                            <div
                                className={`${styles.howToParticipateStepWrapper} col-12 d-flex flex-row justify-content-between align-items-center`}>
                                <div className={`${styles.howToParticipateStepContent}`}>
                                    <h2 className={`${styles.howToParticipateTitle}`}>
                                        Predlagaj
                                    </h2>
                                    <p className={`${styles.howToParticipateStepsText} m-0 col-9`}>
                                        Svoj predlog o porabi participativnega dela proračuna si lahko oddal/-a do
                                        11.7.2022.
                                    </p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className={`${styles.whatIsParticipateWrapper} row justify-content-end`}>
                    <div
                        className={`${styles.howToParticipateStepWrapper} col-10 mx-auto d-flex align-items-center justify-content-end gap: 30px`}>
                        <div className={`${styles.howToParticipateStepContent} text-end col-6`}>
                            <h2 className={`${styles.howToParticipateStepsTitle}`}>
                                Kakšen je proračun?
                            </h2>
                            <p className={`${styles.howToParticipateStepsText} `}>
                                Participativnemu proračunu je namenjenih 960.000 € proračunskih sredstev občine za leti
                                2023 in 2024.
                                Vrednost posameznega predloga je omejena na od 10.000 do 40.000 €.
                            </p>
                        </div>
                        <div className="img-wrapper">
                            <img src={paper} alt=""/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className={`${styles.whatIsParticipateWrapper}`}>
                    <div className={`${styles.howToParticipateStepWrapper} col-10 d-flex align-items-center`}>
                        <div className="img-wrapper">
                            <img src={zarnica} alt=""/>
                        </div>
                        <div className={`${styles.howToParticipateStepContent} col-6`}>
                            <h2 className={`${styles.howToParticipateStepsTitle}`}>
                                Kakšen naj bo predlog?
                            </h2>
                            <p className={`${styles.howToParticipateStepsText}`}>
                                Biti mora izvedljiv v prihajajočem proračunskem obdobju, skladen z zakonodajo in v pristojnosti občine.
                                Vse predloge glede na te kriterije oceni posebna komisija, ki jo imenuje župan.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className={`${styles.whatIsParticipateWrapper} row justify-content-end`}>
                    <div
                        className={`${styles.howToParticipateStepWrapper} col-10 mx-auto d-flex align-items-center justify-content-end`}>
                        <div className={`${styles.howToParticipateStepContent} text-end col-6`}>
                            <h2 className={`${styles.howToParticipateStepsTitle}`}>
                                In če vse štima?
                            </h2>
                            <p className={`${styles.howToParticipateStepsText} `}>
                                Če tvoj predlog ustreza kriterijem, se o njegovi izvedbi neposredno odločijo občanke in
                                občani z glasovanjem v naslednji fazi participativnega proračuna.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <br/>
            <br/>
            <br/>
            <PreviousProjects style={{marginTop: '100px'}}/>
        </div>
    )
}

export default Suggest;