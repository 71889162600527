import logo from './logo.svg';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import Home from './Components/Home/Home';
import Layout from './Components/Layout/Layout';
import WhatIs from './Components/WhatIs/WhatIs';
import 'bootstrap/dist/css/bootstrap.min.css';
import Login from './Components/Login/Login';
import UserContext from './Components/Contexts/user.context';
import { useEffect, useState } from 'react';
import { isLoggedIn } from './utils/authUtils';
import ProtectedLayout from "./Components/Layout/ProtectedLayout";
import ProfilePage from "./Components/ProfilePage/ProfilePage";
import OddajNovPredlog from "./Components/OddajNovPredlog/OddajNovPredlog";
import Projects from "./Components/Projects/Projects";
import ProjectSingle from "./Components/ProjectSingle/ProjectSingle";
import AdminProjects from "./Components/AdminProjects/AdminProjects";
import AdminProject from "./Components/AdminProjects/AdminProject/AdminProject";
import FaqPage from "./Components/FaqPage/FaqPage";
import AdminUsers from "./Components/AdminUsers/AdminUsers";
import AddNewUser from "./Components/AddNewUser/AddNewUser";
import AdminDashboard from "./Components/AdminDashboard/AdminDashboard";
import EditAdmin from "./Components/EditAdmin/EditAdmin";
import PredlagajPage from "./Components/PredlagajPage/PredlagajPage";
import GlasujPage from "./Components/GlasujPage/GlasujPage";
import AboutParticipatoryBudgeting from "./Components/AboutParticipatoryBudgeting/AboutParticipatoryBudgeting";
import PastParticipatoryBudgets from "./Components/PastParticipatoryBudgets/PastParticipatoryBudgets";
import Suggest from "./Components/Suggest/Suggest";
import LoginAdmin from './Components/LoginAdmin/LoginAdmin';

function App() {
    const [user, setUser] = useState();
    const loggedIn = isLoggedIn();

    useEffect(() => {
        if(loggedIn) {
            setUser(JSON.parse(localStorage.getItem('auth')));
        }

    }, []);

    return (
        <UserContext.Provider value={[user, setUser]}>
            <Routes>
                <Route element={<Layout />}>
                    <Route path="/" element={<Home />}/>
                    <Route path="/login" element={<Login />}/>
                    <Route path="/adminlogin" element={<LoginAdmin />}/>
                    <Route path="/faq" element={<FaqPage />}/>
                    <Route path="/about" element={<AboutParticipatoryBudgeting />}/>
                    <Route path="/predlagaj" element={<PredlagajPage />}/>
                    <Route path="/glasuj" element={<GlasujPage />}/>
                    <Route path="/aboutParticipatoryBudgeting" element={<AboutParticipatoryBudgeting />}/>
                    <Route path="/history" element={<PastParticipatoryBudgets />}/>
                    <Route path="/pastParticipatoryBudgets" element={<PastParticipatoryBudgets />}/>
                    <Route path="/suggest" element={<Suggest />}/>
                </Route>
                <Route element={<ProtectedLayout />}>
                    <Route path="/oddajnovpredlog" element={<OddajNovPredlog />}/>
                    <Route path="/whatis" element={<WhatIs />}/>
                    <Route path="/profile" element={<ProfilePage />}/>
                    <Route path="/projects" element={<Projects />}/>
                    <Route path="/projects/:id" element={<ProjectSingle />}/>
                </Route>
                <Route path="/admin" element={<ProtectedLayout />}>
                    <Route path="dashboard" element={<AdminDashboard />}/>
                    <Route path="projects" element={<AdminProjects />}/>
                    <Route path="projects/:id" element={<AdminProject />}/>
                    <Route path="users" element={<AdminUsers />}/>
                    <Route path="users/new" element={<AddNewUser />}/>
                    <Route path="users/edit/:id" element={<EditAdmin />}/>
                </Route>
            </Routes>
        </UserContext.Provider>
    );
}

export default App;
