import dayjs from "dayjs";

export const integerToCurrency = (integer) => {
    return integer.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,').replace(',', '.');
};

export const getProjectStatus = (status) => {
    switch(status) {
        case 'Unchecked':
            return 'Nepregledan';
            break;
        case 'Rejected':
            return 'Zavrnjen';
            break;
        case 'Requires action':
            return 'Zahteva dopolnitev';
            break;
        case 'Approved':
            return 'Odobren';
            break;
        case 'In realization':
            return 'V izvajanju';
            break;
        case 'Voted':
            return 'Izglasovan';
            break;
        case 'Completed':
            return 'Izveden';
            break;
    }
}

export const convertDate = (date) => {
    return dayjs(date).format('D. M. YYYY');
}

export const ADMIN_ROLE = 1;
export const SECRETARY_ROLE = 2;
export const USER_ROLE = 3;
export const MAX_ALLOWED_RESOURCES = 200000;