import axios from 'axios';
import React, {useEffect, useState} from "react";
import styles from './PastParticipatoryBudgets.module.css';
import {Link} from "react-router-dom";
import one from './slika1.svg';

const PastParticipatoryBudgets = () => {

    const [post, setPostArray] = useState([])
    useEffect(() => {
        let userData = JSON.parse(localStorage.getItem('auth'));
        fetch(process.env.REACT_APP_BASE_URL + '/getAllBudgets', {
            headers: {
                'Authorization': 'Bearer ' + userData.token
            }
        })
            .then(response => response.json())
            .then(json => {
                //console.log(json);
                setPostArray([json]);
            })

    },[])

/*console.log(post);*/

    return (
        <div className={`home`}>
            <div className={`${styles.howToParticipateWrapper}`}>
                <div className="container">
                    <div className={`${styles.howToParticipateSteps}`}>
                        <div className={`${styles.howToParticipateStepRow} row`}>
                            <div className={`${styles.howToParticipateStepWrapper} col-12 d-flex flex-row justify-content-between align-items-center`}>
                                <div className={`${styles.howToParticipateStepContent}`}>
                                    <h2 className={`${styles.howToParticipateTitle}`}>
                                        Pretekli praticipativni proračuni
                                    </h2>
                                    <p className={`${styles.howToParticipateStepsText} m-0`}>
                                        Preglej vse potrjene projekte iz preteklih participativnih proračunov in spremljaj njihovo izvedbo.
                                    </p>
                                </div>
                                <div className="img-wrapper">
                                    <img src={one} alt=""/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container d-flex flex-column mt-5">
                {
                    post.map(item => item.map(xx =>
                        <div className={`${styles.pastBudgetsWrapper} d-flex justify-content-between align-items-center mb-3 mb-md-3`}>
                            <div>
                                <p className={`${styles.pastBudgetsWrapperDescription}`}>
                                    Participativno proračun Mestne občine Koper
                                </p>
                                <h2 className={`${styles.pastBudgetsTitle}`}>{xx.name}</h2>
                                <Link to='/projects' className={`btn ${styles.howToParticipateReadMore}`}>
                                    Preveri izbrane projekte in spremljaj izvedbo
                                </Link>
                            </div>
                            <div calssName={`align-items-center`}>
                                <Link to='/projects' className={`btn ${styles.btn_primary_big_override}`}>
                                    Spremljaj izvedbo
                                </Link>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default PastParticipatoryBudgets;