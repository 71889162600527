import styles from './ProfilePage.module.css';
import React, {useContext, useEffect, useState} from "react";
import axios from "axios";
import UserContext from "../Contexts/user.context";
import {useNavigate} from "react-router-dom";
import Swal from "sweetalert2";
import project_sample_image from "../../assets/project-sample.jpg";
import {convertDate, integerToCurrency, MAX_ALLOWED_RESOURCES} from "../../utils";
import project_money_icon from "../../assets/project_money_icon.svg";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faClose} from "@fortawesome/free-solid-svg-icons";
import PreviousProjects from "../Parts/PreviousProjects/PreviousProjects";

const ProfilePage = () => {
    const navigate = useNavigate();
    const [map, setMap] = React.useState(null)
    const [user, setUser] = useContext(UserContext);
    const [projects, setProjects] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        let userData = JSON.parse(localStorage.getItem('auth'));
        axios.get(process.env.REACT_APP_BASE_URL + '/podprtiProjekti', {
            headers: {
                'Authorization': 'Bearer ' + userData.token
            }
        })
            .then(res => {
                setProjects(res.data);
                setIsLoaded(true);
            })
    }, []);

    const removeVote = (projectId, projectCost) => {
        let userData = JSON.parse(localStorage.getItem('auth'));
        axios.delete(process.env.REACT_APP_BASE_URL + '/deleteVote/' + projectId, {
            headers: {
                'Authorization': 'Bearer ' + userData.token
            }
        })
            .then(res => {
                if(res.status === 200) {
                    userData.price = userData.price - projectCost;
                    localStorage.setItem('auth', JSON.stringify(userData));
                    setUser(userData);
                    let newProjects = [...projects];
                    let index = projects.findIndex((project) => project.id === projectId);
                    newProjects.splice(index, 1);
                    setProjects(newProjects);
                }
            })
    }



    return (
        user ?
            <div className={`${styles.projectsWrapper}`}>
                <div className={`${styles.projectsTitleWrapper} mb-5`}>
                    <div className={`container`}>
                        <h1 className={`${styles.projectsTitle}`}>Projekti za katere sem glasoval</h1>
                    </div>
                </div>
                <div className={`${styles.assetsWrapper} container`}>
                    <div className={`${styles.assets}`}>
                        <div className={`${styles.availableAssets} d-flex flex-column`}>
                            <span className={`${styles.assetsTitleWhite}`}>Razpoložljiva sredstva:</span>
                            <span
                                className={`${styles.assetsValueWhite}`}>{integerToCurrency((MAX_ALLOWED_RESOURCES - user.price))} €</span>
                        </div>

                        <div className={`${styles.spentAssets} d-flex flex-column`}>
                            <span className={`${styles.assetsTitle}`}>Razdeljena sredstva:</span>
                            <span className={`${styles.assetsValue}`}>{integerToCurrency(user.price)} €</span>
                        </div>

                        <div className={`${styles.supportedProjects} d-flex flex-column`}>
                            <span className={`${styles.assetsTitle}`}>Podporti projekti:</span>
                            <span className={`${styles.assetsValue}`}>{projects.length}</span>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <h1 className={`${styles.title} mb-4`}>Glasovani projekti</h1>
                </div>
                <div className={`${styles.projects} container`}>
                    {projects.length > 0 ?
                        projects.map((project) => {
                            return (
                                <div className={`${styles.project} col-3 d-flex flex-column`}>
                                    {project.images[0] ?
                                        <img className={`${styles.projectImage}`}
                                             src={process.env.REACT_APP_BASE_STORAGE + '/' + project.images[0].path}
                                             alt=""/>
                                        : <img className={`${styles.projectImage}`}
                                               src={project_sample_image}
                                               alt=""/>
                                    }

                                    <span className={`${styles.projectDate}`}>{convertDate(project.created_at)}</span>
                                    <h2 onClick={() => {
                                        return navigate('/projects/' + project.id)

                                    }} className={`${styles.projectTitle}`}>{project.title}</h2>
                                    <h2 className={`${styles.projectDescription} mb-3`}>{project.detailed_description}</h2>
                                    <div className={`footer d-flex flex-column mt-auto`}>
                                        <div
                                            className={`${styles.projectWorthWrapper} d-flex flex-row justify-content-between align-items-center`}>
                                            <span className={`${styles.projectWorthText}`}><img src="" alt=""/><img
                                                className={`me-1`} src={project_money_icon} alt=""/>Vrednost</span>
                                            <span
                                                className={`${styles.projectWorth} mb-0 mt-auto`}>{integerToCurrency(project.project_cost)} EUR</span>
                                        </div>
                                        <p className={`${styles.removeVoteText} text-center mb-0 mt-2`}>Za ta
                                            projekt si že oddal glas. Ga
                                            želiš odstraniti?</p>
                                        <span className={`btn btn-transparent-big ${styles.voteButton}`}
                                              onClick={() => {
                                                  removeVote(project.id, project.project_cost)
                                              }}><FontAwesomeIcon className={`me-2`}
                                                                  icon={faClose}/>Odstrani glas</span>
                                    </div>


                                </div>
                            )
                        }) : <p>Niste glasovali za nobeden projekt</p>
                    }

                </div>
                <PreviousProjects style={{marginTop: '100px'}} />
            </div> : ''
    )
}

export default ProfilePage;