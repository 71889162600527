import {Nav} from "react-bootstrap";
import {useContext, useEffect, useState} from "react";
import AdminProjects from "../AdminProjects/AdminProjects";
import AdminUsers from "../AdminUsers/AdminUsers";
import UserContext from "../Contexts/user.context";
import styles from "./AdminDashboard.module.css";

const AdminDashboard = () => {

    const [user, setUser] = useContext(UserContext);
    const [items, setItems] = useState('projects')

    useEffect(() => {
        let userData = JSON.parse(localStorage.getItem('auth'));
    }, []);

    return (
        <>
            <div className={`${styles.dashboardTitleWrapper} d-flex flex-row`}>
                <div className={`container d-flex flex-row`}>
                    <div className={`${styles.textWrapper} d-flex flex-column justify-content-center`}>
                        <h1 className={`${styles.dashboardTitle}`}>Nadzorna plošča</h1>
                        <div className={`${styles.itemsSwitcher}`}>
                            <ul>
                                {items === 'projects' ?
                                    <li className={`${styles.predlogiActive}`} >
                                        <span>Predlogi</span>
                                    </li>
                                :
                                    <li className={`${styles.predlogi}`} onClick={() => {setItems('projects')}}>
                                        <span>Predlogi</span>
                                    </li>
                                }

                                {items === 'users' ?
                                    <li className={`${styles.uporabnikiActive}`} >
                                        <span>Uporabniki</span>
                                    </li>
                                    :
                                    <li className={`${styles.uporabniki}`} onClick={() => {setItems('users')}}>
                                        <span>Uporabniki</span>
                                    </li>
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            {items === 'projects' ? <AdminProjects/> : ''}
            {items === 'users' ? <AdminUsers/> : ''}
        </>
    )
}

export default AdminDashboard;