import styles from './Home.module.css';
import {Link} from "react-router-dom";
import homeMainImage from './home-right.svg';
import step1 from './step1.svg';
import step2 from './how_to_vote_2.svg';
import step3 from './how_to_vote_3.svg';
import PreviousProjects from "../Parts/PreviousProjects/PreviousProjects";
const Home = () => {
    return (
        <div className={`home`}>
            <div className="container">
                <div className="row">
                    <div className="col-12 col-lg-6 d-flex flex-column justify-content-center">
                        <h1 className={`${styles.projectTitle}`}>S projektom pravkar uresničujemo participativni proračun za leto 2023 2024</h1>
                        <div className={`${styles.buttons}`}>
                            <Link to="oddajnovpredlog" className={`btn btn-primary-big`}>Prijavi svoj projekt</Link>
                            <Link to="aboutParticipatoryBudgeting" className={`btn btn-transparent-big ms-0 ms-lg-3 mt-3 mt-lg-0`}>Podrobno o participativnem proračunu</Link>
                        </div>
                    </div>
                    <div className="col-12 col-lg-6 mt-3 mt-lg-0 d-none d-lg-block">
                        <img src={homeMainImage} alt=""/>
                    </div>
                </div>
            </div>
            <div className="container mt-5">
                <div className={`${styles.howToParticipateWrapper}`}>
                    <h2 className={`${styles.howToParticipateTitle}`}>Kako sodelujem?</h2>
                    <p className={`${styles.howToParticipateDescription}`}>Sodelovanje preko participativnega proračuna je možno na več načinov. Ali predlagate svoj projekt ali glasujete za predlagane projekte. Projekte, ki so naposled izglasovani je moč spremljati v času izvedbe.</p>
                    <div className={`${styles.howToParticipateSteps} mt-5`}>
                        <div className="row">
                            <div className={`${styles.howToParticipateStepWrapper} col-12 col-lg-4 d-flex flex-row`}>
                                <img src={step1} alt=""/>
                                <div className={`${styles.howToParticipateStepContent}`}>
                                    <h2 className={`${styles.howToParticipateStepsTitle}`}>Predlagaj svoj projekt</h2>
                                    <p className={`${styles.howToParticipateStepsText}`}>Svoj predlog o porabi participativnega dela proračuna si lahko oddal od 11. 7. 2022</p>
                                    <Link to='/projects' className={`${styles.howToParticipateReadMore}`}>Preberi več</Link>
                                </div>
                            </div>
                            <div className={`${styles.howToParticipateStepWrapper} col-12 col-lg-4 d-flex flex-row`}>
                                <img src={step2} alt=""/>
                                <div className={`${styles.howToParticipateStepContent}`}>
                                    <h2 className={`${styles.howToParticipateStepsTitle}`}>Glasuj za najboljše</h2>
                                    <p className={`${styles.howToParticipateStepsText}`}>Glasovanje je zdaj zakjučeno. Občina bo preverila rezultate glasovanja in v naslednjih dneh razglasila projekte, ki bodo uvrščeni v proračun in izvedeni do konca leta 2024</p>
                                    <Link to='/' className={`${styles.howToParticipateReadMore}`}>Preberi več</Link>
                                </div>
                            </div>
                            <div className={`${styles.howToParticipateStepWrapper} col-12 col-lg-4 d-flex flex-row`}>
                                <img src={step3} alt=""/>
                                <div className={`${styles.howToParticipateStepContent}`}>
                                    <h2 className={`${styles.howToParticipateStepsTitle}`}>Spremljaj izvedbo</h2>
                                    <p className={`${styles.howToParticipateStepsText}`}>Ko bo glasovanje o projektih zaključeno, bodo vsi izglasovani predlogi prešli v fazo implementacije.</p>
                                    <Link to='/' className={`btn btn-primary-big`}>Preberi več</Link>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <PreviousProjects style={{marginTop: '100px'}} />

        </div>
    )
}

export default Home;