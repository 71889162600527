import styles from './AdminProjects.module.css';
import {Link, useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {convertDate, getProjectStatus, integerToCurrency} from "../../utils";
import location_icon from './location_icon.svg';
import project_money_icon from "../../assets/project_money_icon.svg";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck} from "@fortawesome/free-solid-svg-icons";
const AdminProjects = () => {
    const [banane, setBanane] = useState('state1')
    const params = useParams();
    const navigate = useNavigate();
    const [projects, setProjects] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [filters, setFilters] = useState({
        title: '',
        area: '',
        status: ''
    });
    const [filteredProjects, setFilteredProjects] = useState([]);

    const [firstPage, setFirstPage] = useState(1);
    const [currentPage , setCurrentPage] = useState(1);
    const [nextPage , setNextPage] = useState(null);
    const [previousPage , setPreviousPage] = useState(null);
    const [lastPage , setLastPage] = useState(null);
    const [firstPageURL , setFirstPageURL] = useState(null);
    const [lastPageURL , setLastPageURL] = useState(null);

    useEffect(() => {
        let userData = JSON.parse(localStorage.getItem('auth'));
        axios.get(process.env.REACT_APP_BASE_URL + '/getPaginatedProjectResources', {                       //axios.get(process.env.REACT_APP_BASE_URL + '/admin/paginateProjects', {

            headers: {
                'Authorization': 'Bearer ' + userData.token
            }
        })
            .then(res => {
                setProjects(Array.from(res.data["data"]))
                setFirstPage(1)
                setCurrentPage(res.data["meta"].current_page)                                                           //setCurrentPage(res.data["current_page"]);
                setLastPage(res.data["meta"].last_page)                                                                 //setLastPage(res.data["last_page"]);
                setFirstPageURL(res.data["links"].first)                                                                //setFirstPageURL(res.data["first_page_url"]);
                setLastPageURL(res.data["links"].last);                                                                 //setLastPageURL(res.data["last_page_url"])
                if(lastPage === currentPage)
                    setNextPage(currentPage)
                else if( (currentPage+1) < lastPage)
                    setNextPage(currentPage+1)
                else
                    setNextPage(lastPage)

                if(lastPage === currentPage)
                    setPreviousPage(currentPage)
                else if( (currentPage-1) >  firstPage)
                    setPreviousPage(currentPage-1)
                else
                    setPreviousPage(firstPage)
            })
    }, []);

    useEffect(() => {
        // Logic to filter data based on filter1 and filter2
        let newData = projects;

        if (filters.title) {
            const regex = new RegExp(filters.title, 'i');
            newData = newData.filter(item => regex.test(item.title));
        }

        if(filters.area) {
            newData = newData.filter(item => item.area === filters.area);
        }

        if(filters.status) {
            newData = newData.filter(item => item.status === filters.status);
        }

        setFilteredProjects(newData);
    }, [filters]);
    const handleChange = (e) => {
        setFilters((prevState) => {
            return {...prevState, [e.target.name]: e.target.value};
        })
    }

    const goToPrevPage = (e) => {
        let userData = JSON.parse(localStorage.getItem('auth'));
        axios.get(process.env.REACT_APP_BASE_URL + '/getPaginatedProjectResources?current_page=' + (currentPage-1), {//axios.get(process.env.REACT_APP_BASE_URL + '/admin/paginateProjects?current_page=' + (currentPage-1), {
            headers: {
                'Authorization': 'Bearer ' + userData.token
            }
        })
            .then(res => {
                setProjects(Array.from(res.data["data"]))                                                               //setProjects(Array.from(res.data["data"]));
                setCurrentPage(res.data["meta"].current_page)                                                           //setCurrentPage(res.data["current_page"]);
                if(lastPage === currentPage)
                    setNextPage(currentPage)
                else if( (currentPage+1) < lastPage)
                    setNextPage(currentPage+1)
                else
                    setNextPage(lastPage)

                if(lastPage === currentPage)
                    setPreviousPage(currentPage)
                else if( (currentPage-1) >  firstPage)
                    setPreviousPage(currentPage-1)
                else
                    setPreviousPage(firstPage)
            })
    }
    const goToNextPage = (e) => {
        let userData = JSON.parse(localStorage.getItem('auth'));
        axios.get(process.env.REACT_APP_BASE_URL + '/getPaginatedProjectResources?current_page=' + (currentPage+1), {//axios.get(process.env.REACT_APP_BASE_URL + '/admin/paginateProjects?current_page=' + (currentPage+1), {
            headers: {
                'Authorization': 'Bearer ' + userData.token
            }
        })
            .then(res => {
                setProjects(Array.from(res.data["data"]))                                                               //setProjects(Array.from(res.data["data"]));
                setCurrentPage(res.data["meta"].current_page)                                                           //setCurrentPage(res.data["current_page"]);
                if(lastPage === currentPage)
                    setNextPage(currentPage)
                else if( (currentPage+1) < lastPage)
                    setNextPage(currentPage+1)
                else
                    setNextPage(lastPage)

                if(lastPage === currentPage)
                    setPreviousPage(currentPage)
                else if( (currentPage-1) >  firstPage)
                    setPreviousPage(currentPage-1)
                else
                    setPreviousPage(firstPage)
            })
    }
    const goToFirstPage = (e) => {
        let userData = JSON.parse(localStorage.getItem('auth'));
        axios.get(process.env.REACT_APP_BASE_URL + '/getPaginatedProjectResources?current_page=' + firstPage, {//axios.get(process.env.REACT_APP_BASE_URL + '/admin/paginateProjects?current_page=' + firstPage, {
            headers: {
                'Authorization': 'Bearer ' + userData.token
            }
        })
            .then(res => {
                setProjects(Array.from(res.data["data"]))                                                               //setProjects(Array.from(res.data["data"]));
                setCurrentPage(res.data["meta"].current_page)                                                           //setCurrentPage(res.data["current_page"]);
                if(lastPage === currentPage)
                    setNextPage(currentPage)
                else if( (currentPage+1) < lastPage)
                    setNextPage(currentPage+1)
                else
                    setNextPage(lastPage)

                if(lastPage === currentPage)
                    setPreviousPage(currentPage)
                else if( (currentPage-1) >  firstPage)
                    setPreviousPage(currentPage-1)
                else
                    setPreviousPage(firstPage)
            })
    }
    const goToLastPage = (e) => {
        let userData = JSON.parse(localStorage.getItem('auth'));
        axios.get(process.env.REACT_APP_BASE_URL + '/getPaginatedProjectResources?current_page=' + lastPage, {//axios.get(process.env.REACT_APP_BASE_URL + '/admin/paginateProjects?current_page=' + lastPage, {
            headers: {
                'Authorization': 'Bearer ' + userData.token
            }
        })
            .then(res => {
                setProjects(Array.from(res.data["data"]))                                                               //setProjects(Array.from(res.data["data"]));
                setCurrentPage(res.data["meta"].current_page)                                                           //setCurrentPage(res.data["current_page"]);
                if(lastPage === currentPage)
                    setNextPage(currentPage)
                else if( (currentPage+1) < lastPage)
                    setNextPage(currentPage+1)
                else
                    setNextPage(lastPage)

                if(lastPage === currentPage)
                    setPreviousPage(currentPage)
                else if( (currentPage-1) >  firstPage)
                    setPreviousPage(currentPage-1)
                else
                    setPreviousPage(firstPage)
            })
    }
    function displayButtons() {
        if (currentPage === firstPage) {
            return (
                <div>
                    <button onClick={goToFirstPage}>first page</button>
                    <button>0</button>
                    <button onClick={goToNextPage}>+1</button>
                    <button onClick={goToLastPage}>last page</button>
                </div>
            );
        } else if ((currentPage > firstPage) && (currentPage < lastPage)) {
            return (
                <div>
                    <button onClick={goToFirstPage}>first page</button>
                    <button onClick={goToPrevPage}>-1</button>
                    <button>0</button>
                    <button onClick={goToNextPage}>+1</button>
                    <button onClick={goToLastPage}>last page</button>
                </div>
            );
        } else if (currentPage === lastPage){
            return (
                <div>
                    <button onClick={goToFirstPage}>first page</button>
                    <button onClick={goToPrevPage}>-1</button>
                    <button>0</button>
                    <button onClick={goToLastPage}>last page</button>
                </div>
            );
        }
    }

    return (
        <div className={`container`}>
            <div className={`${styles.filter} mb-3 mt-4`}>
                <div className="row mb-3">
                    <div className="col-4 d-flex flex-column">
                        <label className={`${styles.filterLabel}`}>Območja</label>
                        {/*<select className={`${styles.selectField}`} name={'area'} onChange={handleChange}>*/}
                        <select className={`${styles.customSelect}`} name={'area'} onChange={handleChange}>
                            <option value="">Vsa območja</option>
                            <option value="Območje 1">Območje 1</option>
                            <option value="Območje 2">Območje 2</option>
                            <option value="Območje 3">Območje 3</option>
                            <option value="Območje 4">Območje 4</option>
                            <option value="Območje 5">Območje 5</option>
                            <option value="Območje 6">Območje 6</option>
                        </select>
                    </div>
                    <div className="col-4 d-flex flex-column">
                        <label className={`${styles.filterLabel}`}>Statusi predlogov</label>
                        <select className={`${styles.customSelect}`} name={'status'} onChange={handleChange}>
                            <option value="">Vsi statusi predlogov</option>
                            <option value="Unchecked">Nepregledan</option>
                            <option value="Rejected">Zavrnjen</option>
                            <option value="Requires action">Dodatna pojasnila</option>
                            <option value="Approved">Potrjen</option>
                            <option value="Voted">Izbran</option>
                            <option value="In realization">V izdelavi</option>
                            <option value="Completed">Zaključen</option>
                        </select>
                    </div>
                    <div className="col-4">
                        {/* radio buttons */}
                        <label className={`${styles.filterLabel}`}>Starost objekta</label>
                        <form className={`${styles.customForm}`} onSubmit={handleChange}>
                            <label className={`${styles.radioButtonLabel} radio-inline`}>
                                {banane === 'state1' ?
                                    <input className={`${styles.radioButtonInput} ml-1`} type="radio" name="name1"
                                           value="value1" checked/>
                                    :
                                    <input className={`${styles.radioButtonInput} ml-1`} type="radio" name="name1"
                                           value="value1" onClick={() => {
                                        setBanane('state1')
                                    }}/>
                                }
                                Vsi predlogi
                            </label>
                            <label className={`${styles.radioButtonLabel} radio-inline`}>
                                {banane === 'state2' ?
                                    <input className={`${styles.radioButtonInput} ml-1`} type="radio" name="name2"
                                           value="value2" checked/>
                                    :
                                    <input className={`${styles.radioButtonInput} ml-1`} type="radio" name="name2"
                                           value="value2" onClick={() => {
                                        setBanane('state2')
                                    }}/>
                                }
                                Izglasovani
                            </label>
                            <label className={`${styles.radioButtonLabel} radio-inline`}>
                                {banane === 'state3' ?
                                    <input className={`${styles.radioButtonInput} ml-1`} type="radio" name="name1"
                                           value="value3" checked/>
                                    :
                                    <input className={`${styles.radioButtonInput} ml-1`} type="radio" name="name1"
                                           value="value3" onClick={() => {
                                        setBanane('state3')
                                    }}/>
                                }
                                Neizglasovani
                            </label>
                        </form>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 d-flex flex-column">
                        <label className={`${styles.filterLabel}`}>Iskalnik</label>{/* former Naziv */}
                        <input className={`${styles.selectField}`} name={'title'} onChange={handleChange}
                               placeholder="Vpiši iskalni niz"/>
                    </div>
                </div>
            </div>
            <div className={`${styles.projects}`}>
                {filteredProjects.length > 0 ?
                    filteredProjects?.map((project) => {
                        return (
                            <div className={`${styles.project} col-3 d-flex flex-column`}>

                                <div className={`d-flex flex-row justify-content-between align-items-center`}>
                                    <span className={`${styles.projectDate}`}>{convertDate(project.created_at)}</span>
                                    {project.status === 'Unchecked' ?
                                        <span className={`${styles.projectStatus}`}
                                              style={{backgroundColor: '#005564'}}>Nepregledan</span>
                                        : ''}
                                    {project.status === 'Rejected' ?
                                        <span className={`${styles.projectStatus}`}
                                              style={{backgroundColor: '#FF0000'}}>Zavrnjen</span>
                                        : ''}
                                    {project.status === 'Requires action' ?
                                        <span className={`${styles.projectStatus}`}
                                              style={{backgroundColor: '#FE8700'}}>Dodatna pojasnila</span>
                                        : ''}
                                    {project.status === 'Approved' ?
                                        <span className={`${styles.projectStatus}`}
                                              style={{backgroundColor: '#1C9702'}}>Potrjen</span>
                                        : ''}
                                    {project.status === 'Voted' ?
                                        <span className={`${styles.projectStatus}`}
                                              style={{backgroundColor: '#01B4D8'}}>Izbran</span>
                                        : ''}
                                    {project.status === 'In realization' ?
                                        <span className={`${styles.projectStatus}`}
                                              style={{backgroundColor: '#7C2D2C'}}>V izdelavi</span>
                                        : ''}
                                    {project.status === 'Completed' ?
                                        <span className={`${styles.projectStatus}`}
                                              style={{backgroundColor: '#B0B0B1'}}>Zaključen</span>
                                        : ''}
                                </div>
                                <h2 onClick={() => {return navigate('/projects/' + project.id)}} className={`${styles.projectTitle}`}>{project.title}</h2>
                                {/* <h2 className={`${styles.projectDescription} mb-3`}>{project.detailed_description}</h2> */}
                                <span className={`${styles.projectLocation}`}>
                                    <img className={`me-1`} src={location_icon} alt=""/>{project.area}
                                </span>
                                <span className={`${styles.projectNumOfVotes}`}>
                                    Število glasov: {(project.votes > 1000) ? project.votes.toLocaleString('en-US', {maximumFractionDigits: 0}) : project.votes}
                                </span>
                                <div className={`footer d-flex flex-column mt-auto`}>
                                    <div
                                        className={`${styles.projectWorthWrapper} d-flex flex-row justify-content-between align-items-center`}>
                                                <span className={`${styles.projectWorthText}`}>
                                                    {/* <img src="" alt=""/> */}
                                                    <img className={`me-1`} src={project_money_icon} alt=""/>Vrednost
                                                </span>
                                        <span
                                            className={`${styles.projectWorth} mb-0 mt-auto`}>{integerToCurrency(project.project_cost)} EUR</span>
                                    </div>
                                    <span className={`btn btn-primary-big ${styles.voteButton}`} onClick={() => {
                                        navigate('/admin/projects/' + project.id)
                                    }}>Preglej predlog</span>
                                </div>


                            </div>
                        )
                    })
                :
                    projects?.map((project) => {
                        return (
                            <div className={`${styles.project} col-3 d-flex flex-column`}>
                                <div className={`d-flex flex-row justify-content-between align-items-center`}>
                                    <span className={`${styles.projectDate}`}>{convertDate(project.created_at)}</span>
                                    {project.status === 'Unchecked' ?
                                        <span className={`${styles.projectStatus}`}
                                              style={{backgroundColor: '#005564'}}>Nepregledan</span>
                                        :
                                        ''
                                    }
                                    {project.status === 'Rejected' ?
                                        <span className={`${styles.projectStatus}`}
                                              style={{backgroundColor: '#FF0000'}}>Zavrnjen</span>
                                        :
                                        ''
                                    }
                                    {project.status === 'Requires action' ?
                                        <span className={`${styles.projectStatus}`}
                                              style={{backgroundColor: '#FE8700'}}>Dodatna pojasnila</span>
                                        :
                                        ''
                                    }
                                    {project.status === 'Approved' ?
                                        <span className={`${styles.projectStatus}`}
                                              style={{backgroundColor: '#1C9702'}}>Potrjen</span>
                                        :
                                        ''
                                    }
                                    {project.status === 'Voted' ?
                                        <span className={`${styles.projectStatus}`}
                                              style={{backgroundColor: '#01B4D8'}}>Izbran</span>
                                        :
                                        ''
                                    }
                                    {project.status === 'In realization' ?
                                        <span className={`${styles.projectStatus}`}
                                              style={{backgroundColor: '#7C2D2C'}}>V izdelavi</span>
                                        :
                                        ''
                                    }
                                    {project.status === 'Completed' ?
                                        <span className={`${styles.projectStatus}`}
                                              style={{backgroundColor: '#B0B0B1'}}>Zaključen</span>
                                        :
                                        ''
                                    }
                                </div>

                                {/* before:
                                <h2 onClick={() => {
                                    return navigate('/projects/' + project.id)

                                }} className={`${styles.projectTitle}`}>{project.title}</h2>
                                <h2 className={`${styles.projectDescription} mb-3`}>{project.detailed_description}</h2>
                                <div className={`footer d-flex flex-column mt-auto`}>
                                    <div
                                        className={`${styles.projectWorthWrapper} d-flex flex-row justify-content-between align-items-center`}>
                                                <span className={`${styles.projectWorthText}`}><img src="" alt=""/><img
                                                    className={`me-1`} src={project_money_icon} alt=""/>Vrednost</span>
                                        <span
                                            className={`${styles.projectWorth} mb-0 mt-auto`}>{integerToCurrency(project.project_cost)} EUR</span>
                                    </div>
                                    <span className={`btn btn-primary-big ${styles.voteButton}`} onClick={() => {navigate('/admin/projects/' + project.id)}}>Preglej predlog</span>
                                </div>

                            */}

                                {/* after: */}
                                <h2 onClick={() => {return navigate('/projects/' + project.id) }} className={`${styles.projectTitle}`}>{project.title}</h2>
                                {/* <h2 className={`${styles.projectDescription} mb-3`}>{project.detailed_description}</h2> */}
                                <span className={`${styles.projectLocation}`}>
                                    <img className={`me-1`} src={location_icon} alt=""/>{project.area}
                                </span>
                                <span className={`${styles.projectNumOfVotes}`}>
                                    Število glasov: {project.votes}
                                </span>
                                <div className={`footer d-flex flex-column mt-auto`}>
                                    <div
                                        className={`${styles.projectWorthWrapper} d-flex flex-row justify-content-between align-items-center`}>
                                                <span className={`${styles.projectWorthText}`}>
                                                    {/* <img src="" alt=""/> */}
                                                    <img className={`me-1`} src={project_money_icon} alt=""/>Vrednost
                                                </span>
                                        <span
                                            className={`${styles.projectWorth} mb-0 mt-auto`}>{integerToCurrency(project.project_cost)} EUR</span>
                                    </div>
                                    <span className={`btn btn-primary-big ${styles.voteButton}`} onClick={() => {
                                        navigate('/admin/projects/' + project.id)
                                    }}>Preglej predlog</span>
                                </div>
                                {/* /after */}

                            </div>
                        )
                    })
                }
            </div>
            {displayButtons()}
        </div>
    )
}

export default AdminProjects;




















