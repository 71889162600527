import styles from './GlasujPage.module.css';
import PreviousProjects from "../Parts/PreviousProjects/PreviousProjects";
import image1 from './assets/image1.svg';
import image2 from './assets/image2.svg';

const GlasujPage = () => {
    return (
        <>
            <div className={`${styles.glasujTitleWrapper}`}>
                <div className={`container`}>
                    <div className="row">
                        <div className="col-12 col-lg-6">
                            <div className={`${styles.glasujtextWrapper} d-flex flex-column justify-content-center`}>
                                <h1 className={`${styles.glasujTitle}`}>Predlagaj</h1>
                                <p className={`${styles.glasujDescription}`}>
                                    Svoj predlog o porabi participativnega dela proračuna si lahko oddal-/a do 11. 7.
                                    2022
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className={`${styles.stepsWrapper}`}>
                            <div className={`${styles.step} d-flex flex-column flex-lg-row`}>
                                <div className={`${styles.stepTextWrapperLeft} d-flex flex-column justify-content-center`}>
                                    <h2 className={`${styles.stepTitle}`}>
                                        Kaj?
                                    </h2>
                                    <p className={`${styles.stepContent}`}>
                                        Med vsemi oddanimi predlogi izbereš tiste, za katere si najbolj želiš, da bi bili uresničeni.
                                    </p>
                                </div>
                                <img src={image1} className={`flex-2`} alt=""/>
                            </div>
                            <div className={`${styles.step} d-flex flex-column flex-lg-row`}>

                                <img src={image2} alt=""/>
                                <div className={`${styles.stepTextWrapperRight} d-flex flex-column justify-content-center`}>
                                    <h2 className={`${styles.stepTitle}`}>
                                        Kdo?
                                    </h2>
                                    <p className={`${styles.stepContent}`}>
                                        Glasoval bo lahko vsakdo s stalnim prebivališčem v Mestni občini Koper, star/-a vsaj 15 let.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <PreviousProjects style={{marginTop: '100px'}}/>
        </>
    )
}

export default GlasujPage;