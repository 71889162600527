import styles from './Projects.module.css';
import React, {useContext, useEffect, useState} from "react";
import axios from "axios";
import UserContext from "../Contexts/user.context";
import {GoogleMap, Marker, useJsApiLoader} from "@react-google-maps/api";
import {useNavigate} from "react-router-dom";
import Swal from "sweetalert2";
import {convertDate, integerToCurrency, MAX_ALLOWED_RESOURCES} from "../../utils";
import project_sample_image from '../../assets/project-sample.jpg';
import project_money_icon from '../../assets/project_money_icon.svg';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faClose, faCoffee} from "@fortawesome/free-solid-svg-icons";
import PreviousProjects from "../Parts/PreviousProjects/PreviousProjects";

const containerStyle = {
    width: '100%',
    height: '400px',
    marginBottom: '20px'
};

const center = {
    lat: 45.544715,
    lng: 13.731956
};

const Projects = () => {

    const [firstPage, setFirstPage] = useState(1);
    const [currentPage , setCurrentPage] = useState(1);
    const [nextPage , setNextPage] = useState(null);
    const [previousPage , setPreviousPage] = useState(null);
    const [lastPage , setLastPage] = useState(null);
    const [firstPageURL , setFirstPageURL] = useState(null);
    const [lastPageURL , setLastPageURL] = useState(null);

    const { isLoaded } = useJsApiLoader({
        id: 'google-maps',
        googleMapsApiKey: "AIzaSyA5FsepVk7Ii6f1_NfnjfniJdw3eU4_11w"
    })
    const navigate = useNavigate();
    const [map, setMap] = React.useState(null)
    const [user, setUser] = useContext(UserContext);
    const [projects, setProjects] = useState([]);
    const [votes, setVotes] = useState([]);
    const [filters, setFilters] = useState({
        area: '',
        status: '',
        title: ''
    })
    const [filteredProjects, setFilteredProjects] = useState([]);

    const onLoad = React.useCallback(function callback(map) {
        // This is just an example of getting and using the map instance!!! don't just blindly copy!
        map.setZoom(12)

        setMap(map)
    }, [])

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, [])

    useEffect(() => {
        let userData = JSON.parse(localStorage.getItem('auth'));
        axios.get(process.env.REACT_APP_BASE_URL + '/getPaginatedProjectResources', {                       //axios.get(process.env.REACT_APP_BASE_URL + '/projects', {
            headers: {
                'Authorization': 'Bearer ' + userData.token
            }
        })
            .then(res => {
                //setProjects(res.data);
                setProjects(Array.from(res.data["data"]))
                setFirstPage(1)
                setCurrentPage(res.data["meta"].current_page)
                setLastPage(res.data["meta"].last_page)
                setFirstPageURL(res.data["links"].first)
                setLastPageURL(res.data["links"].last);
                if(lastPage === currentPage)
                    setNextPage(currentPage)
                else if( (currentPage+1) < lastPage)
                    setNextPage(currentPage+1)
                else
                    setNextPage(lastPage)

                if(lastPage === currentPage)
                    setPreviousPage(currentPage)
                else if( (currentPage-1) >  firstPage)
                    setPreviousPage(currentPage-1)
                else
                    setPreviousPage(firstPage)
            })

        axios.get(process.env.REACT_APP_BASE_URL + '/votes', {
            headers: {
                'Authorization': 'Bearer ' + userData.token
            }
        })
            .then(res => {
                setVotes(res.data);
            })
    }, []);

    useEffect(() => {
        // Logic to filter data based on filter1 and filter2
        let newData = projects;

        if (filters.title) {
            const regex = new RegExp(filters.title, 'i');
            newData = newData.filter(item => regex.test(item.title));
        }

        if(filters.area) {
            newData = newData.filter(item => item.area === filters.area);
        }

        setFilteredProjects(newData);
    }, [filters]);

    const handleChange = (e) => {
        setFilters((prevState) => {
            return {...prevState, [e.target.name]: e.target.value};
        })
    }

    const vote = (projectId) => {
        let userData = JSON.parse(localStorage.getItem('auth'));
        axios.post(process.env.REACT_APP_BASE_URL + '/vote/' + projectId, '', {
            headers: {
                'Authorization': 'Bearer ' + user.token
            }
        })
            .then(res => {
                if(res.status === 200) {
                    userData.price = MAX_ALLOWED_RESOURCES - (res.data.price);
                    localStorage.setItem('auth', JSON.stringify(userData));
                    setUser(userData);
                    let newVote = {
                        project_id: projectId,
                        vote_id: 1
                    }

                    setVotes((prevState) => {
                        return [...votes, newVote];
                    })
                }
            })
            .catch(err => {
                if(err.response.status === 400) {
                    Swal.fire({
                        title: "Napaka",
                        text: "Ni dovolj sredstev",
                        icon: "error"
                    });
                }
            })
    }

    const checkIfVoted = (projectId) => {
        let voted = votes.filter((vote) => vote.project_id === projectId);
        if(voted.length > 0) {
            return true
        } else {
            return false;
        }
    }

    const removeVote = (projectId, projectCost) => {

        let userData = JSON.parse(localStorage.getItem('auth'));
        axios.delete(process.env.REACT_APP_BASE_URL + '/deleteVote/' + projectId, {
            headers: {
                'Authorization': 'Bearer ' + userData.token
            }
        })
            .then(res => {
                if(res.status === 200) {
                    userData.price = userData.price - projectCost;
                    localStorage.setItem('auth', JSON.stringify(userData));
                    setUser(userData);
                    let newVotes = [...votes];
                    const findIndex = newVotes.findIndex((vote) => vote.project_id === projectId);
                    newVotes.splice(findIndex, 1);
                    setVotes(newVotes);

                }
            })
    }

    const goToPrevPage = (e) => {
        let userData = JSON.parse(localStorage.getItem('auth'));
        axios.get(process.env.REACT_APP_BASE_URL + '/getPaginatedProjectResources?current_page=' + (currentPage-1), {//axios.get(process.env.REACT_APP_BASE_URL + '/admin/paginateProjects?current_page=' + (currentPage-1), {
            headers: {
                'Authorization': 'Bearer ' + userData.token
            }
        })
            .then(res => {
                setProjects(Array.from(res.data["data"]))                                                               //setProjects(Array.from(res.data["data"]));
                setCurrentPage(res.data["meta"].current_page)                                                           //setCurrentPage(res.data["current_page"]);
                if(lastPage === currentPage)
                    setNextPage(currentPage)
                else if( (currentPage+1) < lastPage)
                    setNextPage(currentPage+1)
                else
                    setNextPage(lastPage)

                if(lastPage === currentPage)
                    setPreviousPage(currentPage)
                else if( (currentPage-1) >  firstPage)
                    setPreviousPage(currentPage-1)
                else
                    setPreviousPage(firstPage)
            })
    }
    const goToNextPage = (e) => {
        let userData = JSON.parse(localStorage.getItem('auth'));
        axios.get(process.env.REACT_APP_BASE_URL + '/getPaginatedProjectResources?current_page=' + (currentPage+1), {//axios.get(process.env.REACT_APP_BASE_URL + '/admin/paginateProjects?current_page=' + (currentPage+1), {
            headers: {
                'Authorization': 'Bearer ' + userData.token
            }
        })
            .then(res => {
                setProjects(Array.from(res.data["data"]))                                                               //setProjects(Array.from(res.data["data"]));
                setCurrentPage(res.data["meta"].current_page)                                                           //setCurrentPage(res.data["current_page"]);
                if(lastPage === currentPage)
                    setNextPage(currentPage)
                else if( (currentPage+1) < lastPage)
                    setNextPage(currentPage+1)
                else
                    setNextPage(lastPage)

                if(lastPage === currentPage)
                    setPreviousPage(currentPage)
                else if( (currentPage-1) >  firstPage)
                    setPreviousPage(currentPage-1)
                else
                    setPreviousPage(firstPage)
            })
    }
    const goToFirstPage = (e) => {
        let userData = JSON.parse(localStorage.getItem('auth'));
        axios.get(process.env.REACT_APP_BASE_URL + '/getPaginatedProjectResources?current_page=' + firstPage, {//axios.get(process.env.REACT_APP_BASE_URL + '/admin/paginateProjects?current_page=' + firstPage, {
            headers: {
                'Authorization': 'Bearer ' + userData.token
            }
        })
            .then(res => {
                setProjects(Array.from(res.data["data"]))                                                               //setProjects(Array.from(res.data["data"]));
                setCurrentPage(res.data["meta"].current_page)                                                           //setCurrentPage(res.data["current_page"]);
                if(lastPage === currentPage)
                    setNextPage(currentPage)
                else if( (currentPage+1) < lastPage)
                    setNextPage(currentPage+1)
                else
                    setNextPage(lastPage)

                if(lastPage === currentPage)
                    setPreviousPage(currentPage)
                else if( (currentPage-1) >  firstPage)
                    setPreviousPage(currentPage-1)
                else
                    setPreviousPage(firstPage)
            })
    }
    const goToLastPage = (e) => {
        let userData = JSON.parse(localStorage.getItem('auth'));
        axios.get(process.env.REACT_APP_BASE_URL + '/getPaginatedProjectResources?current_page=' + lastPage, {//axios.get(process.env.REACT_APP_BASE_URL + '/admin/paginateProjects?current_page=' + lastPage, {
            headers: {
                'Authorization': 'Bearer ' + userData.token
            }
        })
            .then(res => {
                setProjects(Array.from(res.data["data"]))                                                               //setProjects(Array.from(res.data["data"]));
                setCurrentPage(res.data["meta"].current_page)                                                           //setCurrentPage(res.data["current_page"]);
                if(lastPage === currentPage)
                    setNextPage(currentPage)
                else if( (currentPage+1) < lastPage)
                    setNextPage(currentPage+1)
                else
                    setNextPage(lastPage)

                if(lastPage === currentPage)
                    setPreviousPage(currentPage)
                else if( (currentPage-1) >  firstPage)
                    setPreviousPage(currentPage-1)
                else
                    setPreviousPage(firstPage)
            })
    }
    function displayButtons() {
        if (currentPage === firstPage) {
            return (
                <div>
                    <button onClick={goToFirstPage}>first page</button>
                    <button>0</button>
                    <button onClick={goToNextPage}>+1</button>
                    <button onClick={goToLastPage}>last page</button>
                </div>
            );
        } else if ((currentPage > firstPage) && (currentPage < lastPage)) {
            return (
                <div>
                    <button onClick={goToFirstPage}>first page</button>
                    <button onClick={goToPrevPage}>-1</button>
                    <button>0</button>
                    <button onClick={goToNextPage}>+1</button>
                    <button onClick={goToLastPage}>last page</button>
                </div>
            );
        } else if (currentPage === lastPage){
            return (
                <div>
                    <button onClick={goToFirstPage}>first page</button>
                    <button onClick={goToPrevPage}>-1</button>
                    <button>0</button>
                    <button onClick={goToLastPage}>last page</button>
                </div>
            );
        }
    }

    return (
        <div>
            <div className={`${styles.projectsWrapper} container`}>
                <h1 className={`${styles.title} mt-5 mb-5`}>Predlogi</h1>
                {isLoaded ?
                    <GoogleMap
                        mapContainerStyle={containerStyle}
                        center={center}
                        onLoad={onLoad}
                        onUnmount={onUnmount}
                    >
                        { /* Child components, such as markers, info windows, etc. */ }
                        {filteredProjects.length > 0 ?
                            filteredProjects.map((project) => {
                                    return (
                                        <Marker key={project.id} position={{ lat: parseFloat(project.location_lat), lng: parseFloat(project.location_lng) }} />
                                    )
                                }) : projects.map((project) => {
                                    return (
                                        <Marker key={project.id} position={{ lat: parseFloat(project.location_lat), lng: parseFloat(project.location_lng) }} />
                                    )
                                })
                        }

                        {/*{mapLocation ?
                            <Marker position={{ lat: mapLocation.lat, lng: mapLocation.lng }} /> : ''}
                        <></>*/}
                    </GoogleMap> : <></>}

                <div className={`${styles.filter} mb-3`}>
                    <div className="row mb-3">
                        <div className="col-6 d-flex flex-column">
                            <label className={`${styles.filterLabel}`}>Območja</label>
                            <select className={`${styles.selectField}`} name={'area'} onChange={handleChange}>
                                <option value="">Izberi</option>
                                <option value="Območje 1">Območje 1</option>
                                <option value="Območje 2">Območje 2</option>
                                <option value="Območje 3">Območje 3</option>
                                <option value="Območje 4">Območje 4</option>
                                <option value="Območje 5">Območje 5</option>
                                <option value="Območje 6">Območje 6</option>
                            </select>
                        </div>
                        <div className="col-6 d-flex flex-column">
                            <label className={`${styles.filterLabel}`}>Območja</label>
                            <select className={`${styles.selectField}`} name={'status'} onChange={handleChange}></select>
                        </div>
                        <div className="col"></div>
                    </div>
                    <div className="row">
                        <div className="col-12 d-flex flex-column">
                            <label className={`${styles.filterLabel}`}>Naziv</label>
                            <input className={`${styles.selectField}`} name={'title'} onChange={handleChange} />
                        </div>
                    </div>
                </div>
                <div className={`${styles.projects}`}>
                    {filteredProjects.length > 0 ?
                        filteredProjects.map((project) => {
                                return (
                                    <div className={`${styles.project} col-3 d-flex flex-column`}>
                                        {project.images[0] ?
                                            <img className={`${styles.projectImage}`}
                                                 src={process.env.REACT_APP_BASE_STORAGE + '/' + project.images[0].path}
                                                 alt=""/>
                                            : <img className={`${styles.projectImage}`}
                                                   src={project_sample_image}
                                                   alt=""/>
                                        }

                                        <span className={`${styles.projectDate}`}>{convertDate(project.created_at)}</span>
                                        <h2 onClick={() => {
                                            return navigate('/projects/' + project.id)

                                        }} className={`${styles.projectTitle}`}>{project.title}</h2>
                                        <h2 className={`${styles.projectDescription} mb-3`}>{project.detailed_description}</h2>
                                        <div className={`footer d-flex flex-column mt-auto`}>
                                            <div
                                                className={`${styles.projectWorthWrapper} d-flex flex-row justify-content-between align-items-center`}>
                                                <span className={`${styles.projectWorthText}`}><img src="" alt=""/><img
                                                    className={`me-1`} src={project_money_icon} alt=""/>Vrednost</span>
                                                <span
                                                    className={`${styles.projectWorth} mb-0 mt-auto`}>{integerToCurrency(project.project_cost)} EUR</span>
                                            </div>
                                            {checkIfVoted(project.id) ?

                                                <>
                                                    <p className={`${styles.removeVoteText} text-center mb-0 mt-3`}>Za ta projekt si že oddal glas.
                                                        Ga želiš odstraniti?</p>
                                                    <span className={`btn btn-transparent-big ${styles.voteButton}`}
                                                          onClick={() => {
                                                              removeVote(project.id, project.project_cost)
                                                          }}><FontAwesomeIcon className={`me-2`} icon={faClose} />Odstrani glas</span>
                                                </>
                                                :
                                                <span className={`btn btn-primary-big ${styles.voteButton}`}
                                                      onClick={() => {
                                                          vote(project.id)
                                                      }}><FontAwesomeIcon icon={faCheck}/> Glasuj za projekt</span>}
                                        </div>


                                    </div>
                                )
                        })
                        : projects.map((project) => {
                            return (
                                <div className={`${styles.project} col-3 d-flex flex-column`}>
                                    {project.images[0] ?
                                        <img className={`${styles.projectImage}`}
                                             src={process.env.REACT_APP_BASE_STORAGE + '/' + project.images[0].path}
                                             alt=""/>
                                        : <img className={`${styles.projectImage}`}
                                               src={project_sample_image}
                                               alt=""/>
                                    }

                                    <span className={`${styles.projectDate}`}>{convertDate(project.created_at)}</span>
                                    <h2 onClick={() => {
                                        return navigate('/projects/' + project.id)

                                    }} className={`${styles.projectTitle}`}>{project.title}</h2>
                                    <h2 className={`${styles.projectDescription} mb-3`}>{project.detailed_description}</h2>
                                    <div className={`footer d-flex flex-column mt-auto`}>
                                        <div
                                            className={`${styles.projectWorthWrapper} d-flex flex-row justify-content-between align-items-center`}>
                                                <span className={`${styles.projectWorthText}`}><img src="" alt=""/><img
                                                    className={`me-1`} src={project_money_icon} alt=""/>Vrednost</span>
                                            <span
                                                className={`${styles.projectWorth} mb-0 mt-auto`}>{integerToCurrency(project.project_cost)} EUR</span>
                                        </div>
                                        {checkIfVoted(project.id) ?

                                            <>
                                                <p className={`${styles.removeVoteText} text-center mb-0 mt-2`}>Za ta projekt si že oddal glas. Ga
                                                    želiš odstraniti?</p>
                                                <span className={`btn btn-transparent-big ${styles.voteButton}`}
                                                      onClick={() => {
                                                          removeVote(project.id, project.project_cost)
                                                      }}><FontAwesomeIcon className={`me-2`} icon={faClose} />Odstrani glas</span>
                                            </>
                                            :
                                            <span className={`btn btn-primary-big ${styles.voteButton}`}
                                                  onClick={() => {
                                                      vote(project.id)
                                                  }}><FontAwesomeIcon icon={faCheck} /> Glasuj za projekt</span>}
                                        </div>


                                    </div>
                                )
                        })
                    }
                    {displayButtons()}
                </div>
            </div>
            <PreviousProjects style={{marginTop: '100px'}} />
        </div>
    )
}

export default Projects;