import styles from './PreviousProjects.module.css';
import {Link} from "react-router-dom";
import historyImage from './history.svg';

const PreviousProjects = (props) => {
    return (
        <div className={`${styles.previousProjectsWrapper} ${props.className}`} style={props.style}>
            <div className="container">
                <div className="row">
                    <div className="col-12 col-lg-3 d-flex flex-column">
                        <h2 className={`${styles.title}`}>Pretekli participativni proračuni</h2>
                        <p className={`${styles.text}`}>Preglej vse potrjene projekte iz preteklih participativnih proračunov in spremljaj njihovo izvedbo.</p>
                        <Link to="/history" className={`${styles.link}`}>
                            Preglej zgodovino
                        </Link>
                    </div>
                    <div className="col-12 col-lg-9 d-none d-lg-block">
                        <div style={{position: 'relative'}}>
                            <img className={`${styles.image}`} src={historyImage} alt=""/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PreviousProjects;