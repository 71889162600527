import {Link, Outlet, useNavigate} from "react-router-dom";
import logo from './logo.png';
import access_icon from './access_icon.svg';
import styles from './Layout.module.css';
import {useContext, useEffect, useState} from "react";
import UserContext from "../Contexts/user.context";
import Profile from "./Profile/Profile";
import mok_logo from './mok-logo.svg';

const Layout = () => {
    const [hideProfile, setHideProfile] = useState(null)

    const navigate = useNavigate();
    const [user, setUser] = useContext(UserContext);

    const logout = () => {
        localStorage.removeItem('auth');
        setUser(null);
        return navigate('/');
    }

    useEffect(() => {
        setHideProfile( (window.location.href.includes("/login") || window.location.href.includes("/adminlogin") ) );
    },[])

    return (
        <div className="main">
            <div className={`${styles.header} container d-flex flex-row justify-content-between`}>
                <div className="logo-access d-flex flex-row align-items-center">
                    <Link to="/"><img className={styles.logo} src={logo} alt="Page logo" /></Link>
                    <img className={styles.access_icon} src={access_icon} alt="Accessibility toggle" />
                    <div className={`${styles.lang_switcher}`}>
                        <span>Italiano</span>
                    </div>
                </div>
                {hideProfile === true ? '' : <Profile />}
            </div>
            <div className="content">
                <Outlet />
            </div>
            <div className={`${styles.footer}`}>
                <div className="container">
                    <div className={`${styles.footerContent} d-flex flex-column flex-lg-row justify-content-between align-items-center`}>
                        <a href="https://koper.si/" target="_blank">
                            <img src={mok_logo} alt=""/>
                        </a>
                        <div className={`${styles.links} d-flex flex-column flex-lg-row justify-content-center align-items-center`}>
                            <Link to='/about' className={`${styles.link}`}>O participativnem proračunu</Link>
                            <Link to='/faq' className={`${styles.link} ms-3`}>Podpora uporabnikom</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Layout;