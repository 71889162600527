import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import axios from "axios";
import style from './ProjectSingle.module.css';
import {integerToCurrency} from "../../utils";
import Phases from "../Parts/Phases/Phases";


const ProjectSingle = () => {
    const params = useParams();
    const [project, setProject] = useState();
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        let userData = JSON.parse(localStorage.getItem('auth'));
        axios.get(process.env.REACT_APP_BASE_URL + '/projects/' + params.id, {
            headers: {
                'Authorization': 'Bearer ' + userData.token
            }
        })
            .then(res => {
                setProject(res.data);
                setLoaded(true);
            })
    }, []);

    return (

            loaded ?
                <>
                    <Phases />
                    <div className={`container`}>
                        <div className={`${style.projectHeaderWrapper}`}>
                            {project.images.length > 0 ?
                                <div className={`${style.projectHeader} d-flex flex-column justify-content-end mt-4`}
                                     style={{backgroundImage: 'url(' + process.env.REACT_APP_BASE_STORAGE + '/' + project.images[0].path}}>
                                    <div className={`${style.projectText}`}>
                                        <h1 className={`${style.projectHeaderTitle}`}>{project.title}</h1>
                                        <p className={`${style.projectHeaderName}`}>{project.user.name}</p>
                                    </div>
                                </div>
                                :
                                <div className={`${style.projectHeaderNoImage}`}>
                                <h1 className={`${style.projectHeaderTitleNoImage}`}>{project.title}</h1>
                                    <p className={`${style.projectHeaderNameNoImage}`}>Janez novak, 4.9.2023</p>
                                    <p className={`${style.projectHeaderLocationNoImage}`}>Staro mestno jedro</p>
                                </div>

                            }
                            <div className={`${style.projectWorthWrapper}`}>
                                <p className={`mb-0 ${style.projectWorthTitle}`}>Vrednost projekta</p>
                                <p className={`mb-0 ${style.projectWorthAmount}`}>{integerToCurrency(project.project_cost)} EUR</p>
                            </div>
                        </div>

                        <p className={`mt-4 mb-4 ${style.detailedDescription}`}>{project.detailed_description}</p>
                        <div className={`${style.questions}`}>
                            <div className={`${style.question} mb-2`}>
                                <h2 className={`${style.questionTitle}`}>
                                    zakaj ta predlog? katero temo, težavo oz. izziv projektni predlog naslavlja?
                                </h2>
                                <p className={`${style.questionValue} mb-0`}>{project.question1}</p>
                            </div>

                            <div className={`${style.question} mb-2`}>
                                <h2 className={`${style.questionTitle}`}>
                                    Kaj konkretno predlagate? Kaj je bistvo vašega predloga? Kaj se bo zgodilo?
                                </h2>
                                <p className={`${style.questionValue} mb-0`}>{project.question2}</p>
                            </div>

                            <div className={`${style.question} mb-2`}>
                                <h2 className={`${style.questionTitle}`}>
                                    Kako naj bi se projekt izvedel? Katere aktivnosti bi bilo treba izpeljati?
                                </h2>
                                <p className={`${style.questionValue} mb-0`}>{project.question3}</p>
                            </div>

                            <div className={`${style.question} mb-2`}>
                                <h2 className={`${style.questionTitle}`}>
                                    Kje konkretno naj bi se projekt izvajal?
                                </h2>
                                <p className={`${style.questionValue} mb-0`}>{project.question4}</p>
                            </div>

                            <div className={`${style.question} mb-2`}>
                                <h2 className={`${style.questionTitle}`}>
                                    Časovni okvir: kdaj naj bi se projekt izvedel?
                                </h2>
                                <p className={`${style.questionValue} mb-0`}>{project.question5}</p>
                            </div>

                            <div className={`${style.question} mb-2`}>
                                <h2 className={`${style.questionTitle}`}>
                                    Kaj bi se po izvedbi projekta spremenilo, izboljšalo?
                                </h2>
                                <p className={`${style.questionValue} mb-0`}>{project.question6}</p>
                            </div>
                        </div>
                        <div className={`files mt-5`}>
                            <h3>Dokumenti projekta</h3>
                            {project.documents?.map((document) => {
                                return (
                                    <div className={`document`}>
                                        <a target="_blank" href={`${process.env.REACT_APP_BASE_STORAGE}/` + document.path}
                                           className={`${style.documentLink}`}>{document.name}</a>
                                    </div>
                                )
                            })}

                        </div>
                    </div>
                </>
                : ''

    )
}

export default ProjectSingle;