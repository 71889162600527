import axios from 'axios';
import style from './OddajNovPredlog.module.css';
import React, {useEffect, useState} from "react";
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import Select from 'react-select';
import Swal from "sweetalert2";
import {useNavigate} from "react-router-dom";
import Image from "./Image/Image";

const containerStyle = {
    width: '1000px',
    height: '400px'
  };
  
  const center = {
    lat: 45.544715,
    lng: 13.731956
  };

  const colourOptions = [
    {
        label: 'Ocean',
        value: 'ocean'
    },
    {
        label: 'Earth',
        value: 'earth'
    }
  ]


const OddajNovPredlog = () => {
    const fields = ['title', 'area', 'summary', 'detailed_description', 'question1', 'question2', 'question3', 'question4', 'question5', 'question6', 'project_cost', 'email', 'phone_number'];
    const { isLoaded } = useJsApiLoader({
        id: 'google-maps',
        googleMapsApiKey: "AIzaSyA5FsepVk7Ii6f1_NfnjfniJdw3eU4_11w"
    })

    const [latestBudgetId, setLatestBudgetId] = React.useState(null);

    const navigate = useNavigate();
    const [map, setMap] = React.useState(null)
    const [mapLocation, setMapLocation] = useState(null);
    const [formError, setFormError] = useState({
        title: false,
        summary: false,
        detailed_description: false,
        web_url: false,
        question1: false,
        question2: false,
        question3: false,
        question4: false,
        question5: false,
        question6: false,
        question7: false,
        project_cost: false,
        map_location: false,
        email: false,
        phone_number: false
    })
    const [images, setImages] = useState([
        {
            image: {},
            title: ''
        }
    ]);
    const [projectFormData, setProjectFormData] = useState({
        title: '',
        area: '',
        summary: '',
        detailed_description: '',
        web_url: '',
        question1: '',
        question2: '',
        question3: '',
        question4: '',
        question5: '',
        question6: '',
        project_cost: '',
        images: [],
        documents: [],
        informative_prices: [],
        email: '',
        phone_number: ''
    });

    const onLoad = React.useCallback(function callback(map) {
        // This is just an example of getting and using the map instance!!! don't just blindly copy!
        map.setZoom(12)
    
        setMap(map)
      }, [])
    
      const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
      }, [])

    const handleSubmit = (e) => {
        e.preventDefault();
        let isError = false;
        fields.forEach((field) => {
            if(projectFormData[field] === '') {
                isError = true;
                setFormError((prevState) => {
                    return {
                        ...prevState,
                        [field]: true,
                    }
                })
            } else {
                setFormError((prevState) => {
                    return {
                        ...prevState,
                        [field]: false,
                    }
                })
            }
        })

        if(mapLocation === null) {
            setFormError((prevState) => {
                return {...prevState, mapLocation: true};
            })
            isError = true;
        }

        if(isError) {
            Swal.fire({
                icon: "Polje je obvezno",
                title: "Izpolnite vsa polja!",
            });
            return;
        }
        let user = JSON.parse(localStorage.getItem('auth'));
        const formSubmit = new FormData();
        formSubmit.append('title', projectFormData.title);
        formSubmit.append('area', projectFormData.area);
        formSubmit.append('summary', projectFormData.summary);
        formSubmit.append('detailed_description', projectFormData.detailed_description);
        formSubmit.append('web_url', projectFormData.web_url);
        formSubmit.append('location_lat', mapLocation.lat);
        formSubmit.append('location_lng', mapLocation.lng);
        formSubmit.append('question1', projectFormData.question1);
        formSubmit.append('question2', projectFormData.question2);
        formSubmit.append('question3', projectFormData.question3);
        formSubmit.append('question4', projectFormData.question4);
        formSubmit.append('question5', projectFormData.question5);
        formSubmit.append('question6', projectFormData.question6);
        formSubmit.append('project_cost', projectFormData.project_cost);
        formSubmit.append('email', projectFormData.email);
        formSubmit.append('phone_number', projectFormData.phone_number);
        formSubmit.append('participatory_budget_id', latestBudgetId);

        for (let i = 0; i < projectFormData.images.length; i++) {
            formSubmit.append('images[]', projectFormData.images[i]);
        }
        for (let i = 0; i < projectFormData.documents.length; i++) {
            formSubmit.append('documents[]', projectFormData.documents[i]);
        }
        for (let i = 0; i < projectFormData.informative_prices.length; i++) {
            formSubmit.append('informative_prices[]', projectFormData.informative_prices[i]);
        }


        axios.post(process.env.REACT_APP_BASE_URL + '/projects', formSubmit, {
            headers: {
                'Authorization': 'Bearer ' + user.token
            }
        }, {
        })
        .then(res => {
            if(res.status === 200) {
                Swal.fire({
                    title: "Projekt oddan",
                    text: "Projekt je bil uspešno oddan",
                    icon: "success"
                });

                return navigate('/');
            }
        }) 
    }

    const handleChange = (e) => {
        setProjectFormData((prevState) => {
            return {...prevState, [e.target.name]: e.target.value};
        })
    }

    const onPhotoChange = (e) => {
        setProjectFormData((prevState) => {
            return {...prevState, images: e.target.files}
        })
    }

    const onDocumentsChange = (e) => {
        setProjectFormData((prevState) => {
            return {...prevState, documents: e.target.files};
        })
    }

    const informativePricesChange = (e) => {
        setProjectFormData((prevState) => {
            return {...prevState, informative_prices: e.target.files};
        })
    }

    const handleMapClick = (e) => {
        let lat = e.latLng.lat();
        let lng = e.latLng.lng();

        setMapLocation((prevState) => {
            return {...prevState, lat: lat, lng: lng}
        })

        console.log(lat + ' ' + lng);
    }

    const testSelect = (selected) => {
        console.log(selected);
    }

    const addImage = () => {
        setImages((prevState) => {
            return [...images, {title: ''}]
        })
    }

    const handleImageIpload = (id, image, title) => {
        console.log(title);
        let newImages = [...images];

        newImages[id].image = image;
        newImages[id].title = title;
        setImages(newImages);
        setImages((prevState) => {
            return [...images, {image: image, title: ''}];
        })
    }

    useEffect(() => {
        let userData = JSON.parse(localStorage.getItem('auth'));
        axios.get(process.env.REACT_APP_BASE_URL + '/getEnabledBudgetId', {
            headers: {
                'Authorization': 'Bearer ' + userData.token
            }
        })
            .then(res => {

                setLatestBudgetId(res.data)
                console.log(res.data);

                // setProjects(res.data);
                // setIsLoaded(true);
            })
    }, []);



    return (
        <div className="oddajNovPredlog">
            <div className={`${style.oddajNovPredlogTitleWrapper}`}>
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-lg-6">
                            <h1 className={`${style.oddajNovPredlogTitleWrapperTitle}`}>Oddaj nov predlog</h1>
                            <p className={`${style.oddajNovPredlogTitleWrapperText}`}>Če imaš idejo za nekaj izjemnega in bi hotel, da to izvedemo ali imaš rešitev za nek pereč problem v naši občini, izpolni to vlogo in sodeluj. Mogoče bo ravno tvoj predlog izveden.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-12 col-lg-6">
                        <form className={`${style.oddajNovPredlogForm}`} onSubmit={handleSubmit}>
                            <p className={`${style.requiredFields}`}>Obvezna polja</p>
                            <div className={`${style.formField}`}>
                                <div className={`d-block ${style.formFieldTitleWrapper}`}>
                                    <div className={`d-flex flex-row justify-content-between`}>
                                        <span className={`${style.formFieldTitle} d-block`}>
                                            Naziv predloga
                                        </span>
                                        {formError.title ? <span className={`${style.fieldError}`}>Polje je obvezno</span> : ''}
                                    </div>
                                    <input className={`${style.formInput} ${formError.title ? `${style.inputError}` : ''}`} type="text" name="title"
                                           onChange={handleChange}/>
                                </div>
                            </div>
                            <div className={`${style.formField}`}>
                                <div className={`d-block ${style.formFieldTitleWrapper}`}>
                                    <div className={`d-flex flex-row justify-content-between`}>
                                        <span className={`${style.formFieldTitle} d-block`}>
                                            Povzetek predloga
                                        </span>
                                        {formError.summary ? <span className={`${style.fieldError}`}>Polje je obvezno</span> : ''}
                                    </div>

                                    <span className={`${style.formFieldTitleAdditional} d-block`}>
                                        (največ 200 znakov)
                                    </span>
                                    <textarea className={`${style.formInput} ${formError.summary ? `${style.inputError}` : ''}`} type="text" name="summary" rows={"10"}
                                              onChange={handleChange}/>
                                </div>
                            </div>
                            <div className={`${style.formField}`}>
                                <div className={`d-block ${style.formFieldTitleWrapper}`}>
                                    <div className={`d-flex flex-row justify-content-between`}>
                                        <span className={`${style.formFieldTitle} d-block`}>
                                            Podrobna predstavitev projekta
                                        </span>
                                        {formError.detailed_description ? <span className={`${style.fieldError}`}>Polje je obvezno</span> : ''}
                                    </div>
                                    <textarea className={`${style.formInput} ${formError.detailed_description ? `${style.inputError}` : ''}`} type="text" name="detailed_description"
                                              rows={"5"}
                                              onChange={handleChange}/>
                                </div>
                            </div>
                            <div className={`${style.formField}`}>
                                <div className={`d-block ${style.formFieldTitleWrapper}`}>
                                    <div className={`d-flex flex-row justify-content-between`}>
                                        <span className={`${style.formFieldTitle} d-block`}>
                                            Zakaj ta predlog? Katero temo, težavo oz. izziv projektni predlog naslavlja?
                                        </span>
                                        {formError.question1 ? <span className={`${style.fieldError}`}>Polje je obvezno</span> : ''}
                                    </div>

                                    <textarea className={`${style.formInput} ${formError.question1 ? `${style.inputError}` : ''}`} type="text" name="question1" rows={"10"}
                                              onChange={handleChange}/>
                                </div>
                            </div>
                            <div className={`${style.formField}`}>
                                <div className={`d-block ${style.formFieldTitleWrapper}`}>
                                    <div className={`d-flex flex-row justify-content-between`}>
                                        <span className={`${style.formFieldTitle} d-block`}>
                                            Kaj konkretno predlagate? Kaj je bistvo vašega predloga? Kaj se bo zgodilo?
                                        </span>
                                    </div>

                                    {formError.question2 ? <span className={`${style.fieldError}`}>Polje je obvezno</span> : ''}

                                    <textarea className={`${style.formInput} ${formError.question2 ? `${style.inputError}` : ''}`} type="text" name="question2" rows={"10"}
                                              onChange={handleChange}/>
                                </div>
                            </div>
                            <div className={`${style.formField}`}>
                                <div className={`d-block ${style.formFieldTitleWrapper}`}>
                                    <div className={`d-flex flex-row justify-content-between`}>
                                        <span className={`${style.formFieldTitle} d-block`}>
                                            Kako naj bi se projekt izvedel? Katere aktivnosti bi bilo treba izpeljati?
                                        </span>
                                        {formError.question3 ? <span className={`${style.fieldError}`}>Polje je obvezno</span> : ''}
                                    </div>

                                    <textarea className={`${style.formInput} ${formError.question3 ? `${style.inputError}` : ''}`} type="text" name="question3" rows={"10"}
                                              onChange={handleChange}/>
                                </div>
                            </div>
                            <div className={`${style.formField}`}>
                                <div className={`d-block ${style.formFieldTitleWrapper}`}>
                                    <div className={`d-flex flex-row justify-content-between`}>
                                        <span className={`${style.formFieldTitle} d-block`}>
                                            Kje konkretno naj bi se projekt izvajal?
                                        </span>

                                        {formError.question4 ? <span className={`${style.fieldError}`}>Polje je obvezno</span> : ''}
                                    </div>

                                    <textarea className={`${style.formInput} ${formError.question4 ? `${style.inputError}` : ''}`} type="text" name="question4" rows={"10"}
                                              onChange={handleChange}/>
                                </div>
                            </div>
                            <div className={`${style.formField}`}>
                                <div className={`d-block ${style.formFieldTitleWrapper}`}>
                                    <div className={`d-flex flex-row justify-content-between`}>
                                        <span className={`${style.formFieldTitle} d-block`}>
                                            Časovni okvir: kdaj naj bi se projekt izvedel?
                                        </span>
                                    </div>

                                    {formError.question5 ? <span className={`${style.fieldError}`}>Polje je obvezno</span> : ''}

                                    <textarea className={`${style.formInput} ${formError.question5 ? `${style.inputError}` : ''}`} type="text" name="question5" rows={"10"}
                                              onChange={handleChange}/>
                                </div>
                            </div>
                            <div className={`${style.formField}`}>
                                <div className={`d-block ${style.formFieldTitleWrapper}`}>
                                    <div className={`d-flex flex-row justify-content-between`}>
                                        <span className={`${style.formFieldTitle} d-block`}>
                                            Kaj bi se po izvedbi projekta spremenilo, izboljšalo?
                                        </span>

                                        {formError.question6 ? <span className={`${style.fieldError}`}>Polje je obvezno</span> : ''}
                                    </div>

                                    <textarea className={`${style.formInput} ${formError.question5 ? `${style.inputError}` : ''}`} type="text" name="question6" rows={"10"}
                                              onChange={handleChange}/>
                                </div>
                            </div>
                            <div className={`${style.formField}`}>
                                <div className={`d-block ${style.formFieldTitleWrapper}`}>
                                    <div className={`d-flex flex-row justify-content-between`}>
                                        <span className={`${style.formFieldTitle} d-block`}>
                                            Vrednost projekta (v EUR)
                                        </span>
                                        {formError.project_cost ? <span className={`${style.fieldError}`}>Polje je obvezno</span> : ''}
                                    </div>

                                    <input className={`${style.formInput} ${formError.project_cost ? `${style.inputError}` : ''}`} type="number" name="project_cost"
                                           onChange={handleChange}/>
                                </div>
                            </div>

                            <div className={`${style.formField}`}>
                                <div className={`d-block ${style.formFieldTitleWrapper}`}>
                                    <span className={`${style.formFieldTitle} d-block`}>
                                        Informativne cene
                                    </span>
                                    <input
                                        type="file"
                                        className={`mt-3`}
                                        onChange={informativePricesChange}
                                    />
                                </div>
                            </div>

                            <div className={`${style.formField}`}>
                                <div className={`d-block ${style.formFieldTitleWrapper}`}>
                                    <div className={`d-flex flex-row justify-content-between`}>
                                        <span className={`${style.formFieldTitle} d-block`}>
                                            Email
                                        </span>
                                        {formError.email ? <span className={`${style.fieldError}`}>Polje je obvezno</span> : ''}
                                    </div>

                                    <input className={`${style.formInput} ${formError.email ? `${style.inputError}` : ''}`} type="text" name="email"
                                           onChange={handleChange}/>
                                </div>
                            </div>

                            <div className={`${style.formField}`}>
                                <div className={`d-block ${style.formFieldTitleWrapper}`}>
                                    <div className={`d-flex flex-row justify-content-between`}>
                                        <span className={`${style.formFieldTitle} d-block`}>
                                            Telefonska številka
                                        </span>

                                        {formError.phone_number ? <span className={`${style.fieldError}`}>Polje je obvezno</span> : ''}
                                    </div>

                                    <input className={`${style.formInput} ${formError.phone_number ? `${style.inputError}` : ''}`} type="text" name="phone_number"
                                           onChange={handleChange}/>
                                </div>
                            </div>

                            <p className={`${style.requiredFields}`}>Neobvezna polja</p>
                            <div className={`${style.formField}`}>
                                <div className={`d-block ${style.formFieldTitleWrapper}`}>
                                    <span className={`${style.formFieldTitle} d-block`}>
                                    Spletni naslov predstavitvenega filma predloga
                                    </span>
                                    <span className={`${style.formFieldTitleAdditional} d-block`}>
                                    (YouTube ali Vimeo)
                                    </span>
                                    <input className={`${style.formInput}`} type="text" name="web_url"
                                           onChange={handleChange}/>
                                </div>
                            </div>
                            <div className={`${style.formField}`}>
                                <div className={`d-block ${style.formFieldTitleWrapper}`}>
                                    <span className={`${style.formFieldTitle} d-block`}>
                                        Opisna fotografija predloga
                                    </span>
                                    <input
                                        type="file"
                                        className={`mt-3`}
                                        onChange={onPhotoChange}
                                    />
                                </div>
                            </div>
                            <div className={`${style.formField}`}>
                                <div className={`d-block ${style.formFieldTitleWrapper}`}>
                                    <span className={`${style.formFieldTitle} d-block`}>
                                        Dokumenti
                                    </span>
                                    <input
                                        type="file"
                                        className={`mt-3`}
                                        onChange={onDocumentsChange}
                                        multiple
                                    />
                                </div>
                            </div>
                            <div className={`${style.formField}`}>
                                <div className={`d-block ${style.formFieldTitleWrapper}`}>
                                    <span className={`${style.formFieldTitle} d-block`}>
                                        Območje
                                    </span>
                                    <select className={`${style.formInput}`} name="area" onChange={handleChange}>
                                        <option value="">Izberi</option>
                                        <option value="Območje 1">Območje 1</option>
                                        <option value="Območje 2">Območje 2</option>
                                        <option value="Območje 3">Območje 3</option>
                                        <option value="Območje 4">Območje 4</option>
                                        <option value="Območje 5">Območje 5</option>
                                        <option value="Območje 6">Območje 6</option>

                                    </select>
                                </div>
                            </div>
                            {/*<div className={`${style.formField}`}>
                                <div className={`d-block ${style.formFieldTitleWrapper}`}>
                                    <span className={`${style.formFieldTitle} d-block`}>
                                    Področje delovanja
                                    </span>
                                    <select className={`${style.formInput}`} type="text" name="area" onChange={handleChange}>
                                        <option value="">Izberi</option>
                                        <option value="test1">Test 1</option>
                                        <option value="test2">Test 2</option>
                                        <option value="test3">Test 3</option>
                                    </select>
                                </div>
                            </div>

                            <Select
                                isMulti
                                name="colors"
                                options={colourOptions}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                onChange={(selected) => testSelect(selected)}
                            />*/}
                            <div className={`${style.formField}`}>
                                <div className={`d-block ${style.formFieldTitleWrapper}`}>
                                    {isLoaded ?
                                        <GoogleMap
                                            mapContainerStyle={containerStyle}
                                            center={center}
                                            onLoad={onLoad}
                                            onUnmount={onUnmount}
                                            onClick={(e) => handleMapClick(e)}
                                        >
                                            { /* Child components, such as markers, info windows, etc. */}
                                            {mapLocation ?
                                                <Marker position={{lat: mapLocation.lat, lng: mapLocation.lng}}/> : ''}
                                            <></>
                                        </GoogleMap> : <></>}
                                </div>
                            </div>

                            <button type="submit" className={`btn btn-primary-big`}>Oddaj svoj predlog</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OddajNovPredlog;