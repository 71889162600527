import {Link, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import axios from "axios";
import style from './AdminUsers.module.css';
import {ADMIN_ROLE, SECRETARY_ROLE, USER_ROLE} from "../../utils";

const AdminUsers = () => {
    const [area2, setArea2] = useState(0)
    const [role2, setRole2] = useState('nic')


    const params = useParams();
    const [users, setUsers] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [filters, setFilters] = useState({
        area: '',
        role: 0
    });

    const [filtered, isFiltered] = useState(false);

    const [filteredUsers, setFilteredUsers] = useState([]);

    useEffect(() => {
        let userData = JSON.parse(localStorage.getItem('auth'));
        axios.get(process.env.REACT_APP_BASE_URL + '/admin/users', {
            headers: {
                'Authorization': 'Bearer ' + userData.token
            }
        })
            .then(res => {
                setUsers(res.data);
                setLoaded(true);
            })
    }, []);

    useEffect(() => {
        // Logic to filter data based on filter1 and filter2
        let newData = users;

        if (filters.title) {
            const regex = new RegExp(filters.title, 'i');
            newData = newData.filter(item => regex.test(item.title));
        }

        if(filters.role) {
            newData = newData.filter(item => item.role === filters.role);
        }

        setFilteredUsers(newData);
    }, [filters]);

    const handleChange = (e) => {
        isFiltered(true);
        setRole2(e.target.value);
        /*
        if(e.target.name === 'role') {
            setFilters((prevState) => {
                return {...prevState, [e.target.name]: parseInt(e.target.value)};
            })
        } else {
            setFilters((prevState) => {
                return {...prevState, [e.target.name]: e.target.value};
            })
        }*/
    }
    const handleChangeArea = (e) => {
        isFiltered(true);
        if(e.target.value === '' || e.target.value === null || e.target.value == '' || e.target.value == null ) {
            e.target.value = 'nic'
            setArea2(e.target.value)
        }
        else
            setArea2(e.target.value)
    }

    function makeQuery() {
        let area = area2;
        let role = role2
        let userData = JSON.parse(localStorage.getItem('auth'));
        axios.get(process.env.REACT_APP_BASE_URL + '/admin/users/filtered/' + area + '|' + role, {
            headers: {
                'Authorization': 'Bearer ' + userData.token
            }
        })
        .then(res => {
            console.log('--------------')
            console.log(res.data)
            console.log('--------------')
            setFilteredUsers(res.data)
        })
    }

    function displayRoleAsSpan(role) {
        let returned;
        if (role === ADMIN_ROLE)
            returned = (
                <span className={`${style.role}`}>Administrator</span>
            )

        else if (role === SECRETARY_ROLE)
            returned = (
                <span className={`${style.role}`}>Tajnik</span>
            )

        else if (role === USER_ROLE)
            returned = (
                <span className={`${style.role}`}>Uporabnik</span>
            )

        return returned;
    }

    return (
        <div className={`container mt-5`}>
            <div className={`${style.filter} mb-3`}>
                <div className="row">
                    <div className={`col-6 d-flex flex-row`}>
                        <div className={`${style.filterField} d-flex flex-column`}>
                            <label className={`${style.filterLabel}`}>Območja</label>
                            <select className={`${style.customSelect}`} name={'area'} onChange={handleChangeArea}>
                                <option value="">Izberi</option>
                                <option value="1">KS Koper</option>
                                <option value="2">Območje 2</option>
                                <option value="3">Območje 3</option>
                                <option value="4">Območje 4</option>
                                <option value="5">Območje 5</option>
                                <option value="6">Območje 6</option>
                            </select>
                        </div>
                        <div className={`${style.filterField} d-flex flex-column`}>
                            <label className={`${style.filterLabel}`}>Uporabniška vloga</label>
                            <select className={`${style.customSelect}`} name={'role'} onChange={handleChange}>
                                <option value="">Vse uporabniške vloge</option>
                                <option value={"" + ADMIN_ROLE}>Administratorji</option>
                                <option value={"" + SECRETARY_ROLE}>Tajniki</option>
                                <option value={"" + USER_ROLE}>Uporabniki</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-2 d-flex flex-column justify-content-center align-items-end">
                        <button className={`btn btn-primary p-3`} onClick={makeQuery}>search</button>
                    </div>
                    <div className="col-4 d-flex flex-column justify-content-center align-items-end">
                        <Link to={'/admin/users/new'} className={`btn btn-primary p-3`}>Dodaj novega uporabnika</Link>
                    </div>
                </div>
            </div>
            <div className={`${style.users}`}>
                {filtered ?
                    filteredUsers.map((user) => {
                        return (
                            <div className={`${style.user} d-flex flex-column`}>
                                <div className={`d-flex flex-row justify-content-between`}>
                                    {/*<span className={`${style.role}`}>{user.role === 2 ? 'Administrator' : ''}</span>
                                    <span className={`${style.role}`}>{user.role === 1 ? 'Urednik' : ''}</span>
                                    <span className={`${style.role}`}>{user.role === 0 ? 'Uporabnik' : ''}</span>*/}
                                    {displayRoleAsSpan(user.role)}
                                    <Link className={`${style.edit}`} to={`/admin/users/edit/${user.id}`}>Uredi</Link>
                                </div>
                                <h2 className={`${style.name}`}>{user.name}</h2>
                            </div>
                        )
                    })
                :
                    users.map((user) => {
                        return (
                            <div className={`${style.user} d-flex flex-column`}>
                                <div className={`d-flex flex-row justify-content-between`}>
                                    {/*{user.role === 2 ?
                                        <span className={`${style.role}`}>Administrator</span>
                                    :
                                        <span className={`${style.role}`}>Urednik</span>
                                    }*/}
                                    {displayRoleAsSpan(user.role)}
                                    <Link className={`${style.edit}`} to={`/admin/users/edit/${user.id}`}>Uredi</Link>
                                </div>
                                <h2 className={`${style.name}`}>{user.name}</h2>
                                {user.local_community ?
                                    <span className={`${style.localCommunityText}`}>{user.local_community.name}</span>
                                :
                                    ''
                                }
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default AdminUsers;